import { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import debounce from 'lodash.debounce';
import googleLocationService, { PlaceT } from 'api/modules/location-autocomplete';
import type { AddressT } from 'Helpers/googleApi';
import { DebouncedFunc } from 'lodash';

type tyDebouncer = DebouncedFunc<(query: string) => Promise<void>>;

const SEARCH_DELAY = 1500;
const MAX_WAIT = 10000;

function checkGeolocationAvailability() {
  return 'geolocation' in navigator;
}

async function readUserGeoCoords(): Promise<ICoords> {
  return new Promise((resolve, reject) => {
    const geolocationAvailable = checkGeolocationAvailability();

    if (!geolocationAvailable) {
      return void reject('Not supported');
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        resolve({ lat, lng });
      },
      (err) => {
        reject(err);
      },
      {},
    );
  });
}

export default function useLocationSearch() {
  const [list, setList] = useState<PlaceT[]>([]);
  const [loading, setLoading] = useState(false);

  const myDebRef = useRef<tyDebouncer | undefined>(undefined);

  const guessLocation = useMemo(() => {
    const asyncGuess = async (query: string) => {
      setLoading(true);
      const data = await googleLocationService().guess(query.trim());
      setList(data);
      setLoading(false);
    };

    const myDebFn = debounce(asyncGuess, SEARCH_DELAY, { maxWait: MAX_WAIT });
    myDebRef.current = myDebFn;
    return myDebFn;
  }, []);

  useEffect(() => {
    return () => {
      const cu = myDebRef.current;
      if (cu) {
        cu.cancel();
      }
    };
  }, []);

  const resetList = useCallback(() => {
    setList([]);
  }, []);

  const geocodeUserLocation = useCallback(async (): Promise<{
    formatted_address: string;
    location: any;
    address: AddressT | null;
  } | void> => {
    try {
      const coords = await readUserGeoCoords();

      const { data } = await googleLocationService().reverseGeocode(coords);
      return googleLocationService().formatReverseGeocodeResult(data);
    } catch {
      // Failed to read user location
    }
  }, []);

  return {
    list,
    loading,
    guessLocation,
    resetList,
    geocodeUserLocation,
    getPlaceCoordinatesById: googleLocationService().getPlaceCoordinatesById,
  };
}
