import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Country, getCountries } from 'react-phone-number-input';
import codes, { ICountryCodeItem } from 'country-calling-code';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { useNavigate } from "react-router";
import cx from 'classnames';

import { ReactComponent as BotIcon } from 'Assets/chat-bot.svg';
import { ReactComponent as PhoneIcon } from 'Assets/phone.svg';
import { ReactComponent as UserIcon } from 'Assets/user.svg';
import { ReactComponent as MailIcon } from 'Assets/mail.svg';
import { ReactComponent as SearchIcon } from 'Assets/bitcoin-icons--search-outline.svg';

import { TitleBlock, Button, Input, Dropdown, Loader } from 'Components';
import DropdownMobile from 'Components/DropdownMobile/DropdownMobile';
import { setFirstTimeLogin, setUserEmail } from 'Store/modules/user';
import { registerUserRequest } from 'Store/modules/authorization';
import PhoneNumber, { Flag } from './PhoneNumber/PhoneNumber';
import { useDeviceType } from 'Helpers/responsiveContainers';
import runApi, { ResultApiTypeT } from 'api/base/runApi';
import useChangeLanguage from 'Hooks/useChangeLanguage';
import styles from './RegistrationForm.module.scss';
import type { RegisterT } from 'api/modules/user';
import { runNotify } from 'Helpers/notifications';
import { PageConfigT } from 'Store/modules/page';
import { validField } from 'Helpers/validForm';
import { formatInput } from 'Helpers/strings';
import useTranslate from "Hooks/useTranslate";
import { defaultLanguage } from "Helpers/i18";
import Modal from 'Components/Modal/Modal';
import { AppStateT } from 'Store/modules';
import Store from './Store';


interface IRegistrationForm {
    email: string | null;
    actualLanguage?: string;
}

export type LocalStateT = {
    focusPhoneStatus: boolean,
    countryCode: Country,
    showCountry: boolean,
    countrys: ICountryCodeItem[],
    firstName: string,
    lastName: string,
    title: string,
    salutation: string
    phoneNumber: string,
    phoneNumberDigits: string,
    loading: boolean,
    valid: boolean,
    errors: string[],
    language: string
}

const initialState: LocalStateT = {
    focusPhoneStatus: false,
    countryCode: 'PL',
    showCountry: false,
    countrys: [],
    firstName: '',
    lastName: '',
    title: '',
    salutation: '',
    phoneNumber: '',
    phoneNumberDigits: '',
    loading: false,
    valid: false,
    errors: [],
    language: '',
}

function RegistrationForm(props: IRegistrationForm) {
    const { email } = props;
    const { isMobile } = useDeviceType();
    const { i18n } = useTranslation();
    const t = useTranslate();
    const dispatch = useDispatch();
    const [searchKey, setSearchKey] = useState('');
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [showTitle, setShowTitle] = useState(pageConfig.pax.title.titleByLocation.includes(props.actualLanguage?.toUpperCase() || defaultLanguage));
    const salutationOptions = Object.entries(pageConfig.pax.salutation.salutationNames).filter(data => data[1]).sort((a, b) => a[1] < b[1] ? -1 : 1).map(data => {
        return { text: data[1] ? t('registration.title' + data[1].replace(".", "")) : '-', value: data[1] ? data[0] : '' }
    });
    const navigate = useNavigate();
    const { sortedTranslatedLanguages, actualLanguage, changeAppLanguage } = useChangeLanguage();

    const languageOptions = useMemo(() => sortedTranslatedLanguages.map(language => ({
        ...language,
        text: t(language.displayName),
        key: `lang-${language.value}`,
    })), [sortedTranslatedLanguages, t]);

    const titleOptions = useCallback(() => {
        const emptyOption = { text: '-', value: '' };
        const options = Object.entries(pageConfig.pax.title.titleNames)
            .sort((a, b) => a[1] < b[1] ? -1 : 1)
            .map(r => {
                const key = `registration.${r[0]}`;
                const text = !r[1] ? '-' : t(key);
                const value = pageConfig.pax.title.titleEnum[r[0]] || '';
                return { text, value };
            })
        return [emptyOption, ...options];
    }, [pageConfig.pax, t]);


    const [state, setState] = useReducer(Store, {
        ...initialState,
        title: titleOptions()?.[0]?.value,
        //salutation: salutationOptions?.[0]?.value,
        countryCode: 'PL',
        countrys: codes.filter((country: ICountryCodeItem) => getCountries().includes(country.isoCode2 as any)),
    });

    useEffect(() => {
        if (salutationOptions.length > 0 && !state.salutation) {
            setSt('salutation', salutationOptions[0].value);
        }
    }, [salutationOptions, state.salutation]);

    useEffect(() => {
        setShowTitle(pageConfig.pax.title.titleByLocation.includes(state.countryCode.toUpperCase()));
    }, [state.countryCode, pageConfig.pax.title]);
    
    useEffect(() => {
        const getKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSt('showCountry', false);
            }
        };

        if (state.showCountry) {
            setSearchKey('');
            document.addEventListener('keydown', getKey);
        }
    }, [state.showCountry, setSearchKey]);

    useEffect(() => {
        if (state.phoneNumber.length >= 12 && state.firstName.length >= 2 && state.lastName.length >= 2 && validField('emailSimple', email)) {
            return setSt('valid', true);
        }
        setSt('valid', false);
    }, [state.firstName, state.lastName, state.phoneNumber, email]);

    const selectCountryDirection = (country: ICountryCodeItem) => {
        setState({ type: 'SET_COUNTRY', data: country.isoCode2 });
    }

    const setSt = (dataType: string, data: any) => {
        setState({ type: 'SET_DATA', dataType, data });
    }

    const actionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setUserEmail(event.target.value?.replace(' ', '').toLowerCase()))
    }, [dispatch]);

    const goToLogin = () => {
        navigate('/login');
    };

    const langCountryAdapter = useMemo(() => (language: string, countryName: string): string => {
        switch (language.toUpperCase()) {
            case 'PT-PT': return 'pt';
            case 'CS-CZ': return countryName.toUpperCase().indexOf('SLOVAK') >= 0 ? 'SK' : 'cz';
            case 'EN-US': return 'gb';
        }
        return language;
    }, []);

    useEffect(() => {
        const language = actualLanguage || defaultLanguage;
        const country = state.countrys.find(c => langCountryAdapter(c.isoCode2, '').toUpperCase() === language.toUpperCase());
        if (country) {
            setState({ type: 'SET_COUNTRY', data: country.isoCode2 });
        }
    }, [actualLanguage, state.countrys, langCountryAdapter])

    const actionSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const dataSend: RegisterT = {
            firstName: state.firstName,
            lastName: state.lastName,
            salutation: state.salutation,
            email: email?.replace(' ', '').toLowerCase() as string,
            phoneNumber: state.phoneNumber,
            location: { countryCode: state.countryCode },
            language: actualLanguage
        }

        if (showTitle) {
            dataSend.title = state.title;
        }

        setSt('loading', true);
        runApi('user/register', dataSend, (r: ResultApiTypeT) => {
            setSt('loading', false)
            if (r.result) {
                runNotify({ message: t('registration.successCode'), type: 'success' });
                dispatch(registerUserRequest(dataSend));
                dispatch(setFirstTimeLogin(true));
                navigate('/confirm');
            } else {
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
                setSt('errors', r.error.errors.map(e => e.context.key));
            }
        });
    }

    return (
        <>
            <div className={cx(styles.root, { [styles.isNotMobile]: !isMobile })}>
                {state.loading && <div className={styles.loading}><Loader /></div>}
                <form onSubmit={actionSubmit} >
                    {<div className={styles.isMobile ? styles.isMobileMargin : styles.logo}><BotIcon /></div>}
                    <TitleBlock
                        title={t('registration.header')}
                        subtitle={t('registration.subtitle')}
                        withIcon={false}
                        className={styles.titleHeader}
                    />
                    <div className={styles.names}>
                        {!isMobile ? (
                        <>
                            <Dropdown
                                label={t('labels.title')}
                                name={'salutation_user'}
                                options={salutationOptions}
                                onChange={(value) => setSt('salutation', value.value)}
                                value={state.salutation}
                                className={styles.titleName}
                                key={`lang-${i18n.language}`}
                                id={'select_register_salutation'}
                            />
                        </>
                        ) : (
                        <>
                            <DropdownMobile
                                label={t('labels.title')}
                                name={'salutation_user'}
                                options={salutationOptions}
                                onChange={(value) => setSt('salutation', value.value)}
                                value={state.salutation}
                                className={styles.titleName}
                                key={`lang-${i18n.language}`}
                                id={'select_register_salutation'}
                            />
                        </>
                        )}
                        {(showTitle && !isMobile) && (
                            <>
                                <Dropdown
                                    label={t('labels.salutation')}
                                    name={'title_user'}
                                    options={titleOptions()}
                                    onChange={(value) => setSt('title', value.value)}
                                    value={state.title}
                                    className={styles.title}
                                    id={'select_register_title'}
                                />
                            </>
                        )}
                        {(showTitle && isMobile) &&
                            <DropdownMobile
                                label={t('labels.salutation')}
                                name={'title_user'}
                                options={titleOptions()}
                                onChange={(value) => setSt('title', value.value)}
                                value={state.title}
                                className={styles.title}
                                id={'select_register_title'}
                            />
                        }
                        {!showTitle &&
                            <Input
                                label={t('shop.formFirstName')}
                                value={state.firstName}
                                onChange={ev => setSt('firstName', formatInput(ev.target.value))}
                                className={cx(styles.input, { [styles.error]: state.errors.some(err => err === 'firstName') })}
                                Icon={UserIcon}
                                onFocus={() => setState({ type: 'CLEAR_ERROR', data: 'firstName' })}
                                id={'input_register_firstname'}
                            />
                        }
                    </div>
                    {showTitle &&
                        <Input
                            label={t('shop.formFirstName')}
                            value={state.firstName}
                            onChange={ev => setSt('firstName', formatInput(ev.target.value))}
                            className={cx(styles.input, { [styles.error]: state.errors.some(err => err === 'firstName') })}
                            Icon={UserIcon}
                            onFocus={() => setState({ type: 'CLEAR_ERROR', data: 'firstName' })}
                            id={'input_register_firstname'}
                        />
                    }
                    <Input
                        label={t('shop.formLastName')}
                        value={state.lastName}
                        onChange={ev => setSt('lastName', formatInput(ev.target.value))}
                        className={cx(styles.input, { [styles.error]: state.errors.some(err => err === 'lastName') })}
                        Icon={UserIcon}
                        onFocus={() => setState({ type: 'CLEAR_ERROR', data: 'lastName' })}
                        id={'input_register_lastname'}
                    />
                    <Input
                        label={t('labels.email')}
                        Icon={MailIcon}
                        value={email?.replace(' ', '').toLowerCase() || ''}
                        className={cx(styles.input, { [styles.error]: state.errors.some(err => err === 'email') })}
                        onChange={actionChange}
                        name='email'
                        id={'input_register_email'}
                    />
                    <div className={cx(styles.PhoneInputSelectCountry, { [styles.countryError]: state.errors.some(err => err === 'phoneNumber'), [styles.PhoneInputSelectCountryMobile]: isMobile, [styles.PhoneNumberFocus]: state.focusPhoneStatus })}>
                        <div className={cx(styles.PhoneInputSelectBody)}>

                            <div className={styles.PhoneIco}>
                                <PhoneIcon />
                            </div>

                            <PhoneNumber
                                countryCode={state.countryCode}
                                onChange={(value) => setSt('phoneNumber', value)}
                                label={t('labels.phone')}
                                setFocus={status => setSt('focusPhoneStatus', status)}
                                countrys={state.countrys}
                                setShowCountry={(value) => setSt('showCountry', value)}
                                className={cx({ [styles.phoneError]: state.errors.some(err => err === 'phoneNumber') })}
                                onFocus={() => setState({ type: 'CLEAR_ERROR', data: 'phoneNumber' })}
                                onChangePhoneDigits={data => setState({ type: 'SET_PHONE_NUMBER_DIGITS', data })}
                            />
                        </div>
                    </div>
                    {!isMobile ? (
                    <>
                        <Dropdown
                            label={t('registration.chooseLanguage')}
                            name={'language'}
                            value={actualLanguage}
                            options={languageOptions}
                            onChange={({ value }) => changeAppLanguage(value)}
                            className={cx(styles.input, styles.selectLang)}
                            id={'select_register_language'}  
                        />
                    </>
                    ) : (
                    <>
                        <DropdownMobile
                            label={t('registration.chooseLanguage')}
                            name={'language'}
                            value={actualLanguage}
                            options={languageOptions}
                            onChange={({ value }) => changeAppLanguage(value)}
                            className={cx(styles.input, styles.selectLang)}
                            id={'select_register_language'}
                        />
                    </>
                    )}

                    <div className={styles.buttonGroup}>
                        <Button type="submit" className={styles.button} disabled={!state.valid} id={'button_registration_form_submit'}>
                            {t('registration.signUp')}
                        </Button>
                        {!isMobile &&
                            <Button className={styles.buttonBack} onClick={goToLogin} id={'button_registration_form_back'}>
                                {t('common.goBack')}
                            </Button>}
                    </div>
                </form>
            </div>
            <Modal
                open={state.showCountry}
                onClose={() => setSt('showCountry', false)}
                renderCloseButtonInHeader={true} 
                headerRight={(
                    <Input
                    Icon={SearchIcon}
                    value={searchKey}
                    className={cx(styles.input, styles.searchWord)}
                    onChange={(e) => setSearchKey(e.target.value)}
                    name='searchWord'
                    id={'searchWord'}
                />
                    
                  )}
            >
                <div className={styles.countryslist} style={{ zIndex: 10000 }}>
                    {state.countrys.filter((country: ICountryCodeItem) => searchKey === '' ? true : country.country.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0).map((country: ICountryCodeItem) => (
                        <div
                            style={state.countryCode === country.isoCode2 ? { backgroundColor: '#2fc3dd' } : {}}
                            className={styles.country}
                            key={`country_${country.isoCode2}`}
                            onClick={() => selectCountryDirection(country)}
                        >
                            <div className={styles.countryFlag}>
                                <Flag code={country.isoCode2} title={country.country} />
                            </div>
                            <div className={styles.name}>{country.country}</div>
                            <div className={styles.code}>+{country.countryCodes[0]}</div>
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    )
}

export default RegistrationForm;
