import { Message, MessageGroup, Avatar } from '@chatscope/chat-ui-kit-react';
import React, { useEffect } from 'react';
import cx from 'classnames';

import ChatBotAnimatedDots from 'PageComponents/Chat/ChatBotAnimatedDots/ChatBotAnimatedDots';
import { TyShortcutSpanMaker } from 'PageComponents/Chat/Chat/Chat';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import { ClaimHistoryT } from 'api/modules/case-resolution';
import mGStyles from './ChatMessageGroup.module.scss';
import ChatBotIcon from 'Assets/chat_bot_small.svg';
import styles from './ChatMessageGroup.module.scss';
import { CLAIM_STATUSES } from 'Config/statuses';

export interface IMessage {
  message?: string;
  render?: () => React.ReactNode;
  className?: string;
  renderClassName?: string;
  messageClassName?: string;
  status?: string;
  ind?: number;
}

interface IMessageGroup {
  direction: 'incoming' | 'outgoing';
  data: IMessage[];
  className?: string;
  renderClassName?: string;
  avatar?: string;
  as?: any;
  caseBlockMaker?: TyShortcutSpanMaker;
  statusInfo?: ClaimHistoryT;
  isBeforeLastVedict?: boolean;
}

// const hideAllPreviousBotAvatarsInChat = () => {
//   const allAvatarsInChat = document.querySelectorAll(".cs-message-group--incoming .cs-avatar");
//   for (let i = 0; i <= allAvatarsInChat.length - 2; i += 1) {
//     const curr = allAvatarsInChat[i];
//     curr.classList.add('hide');
//   }
//   allAvatarsInChat[allAvatarsInChat.length - 1].classList.remove('hide');
// }

const scrollHelper = {
  scrollStarterTimout: undefined,
  stopperTimout: undefined,
  scrollerInterval: undefined,
  canScroll: true,
} as {
  scrollStarterTimout: NodeJS.Timeout | undefined;
  stopperTimout: NodeJS.Timeout | undefined;
  scrollerInterval: ReturnType<typeof window.setInterval> | undefined;
  canScroll: boolean;
};

const scrollChatDown = (messageCountInLastGroup: number) => {
  // console.log("dai");
  if (scrollHelper.scrollStarterTimout) {
    window.clearTimeout(scrollHelper.scrollStarterTimout);
  }

  scrollHelper.scrollStarterTimout = setTimeout(() => {
    scrollHelper.canScroll = true;
    if (scrollHelper.scrollerInterval) {
      window.clearInterval(scrollHelper.scrollerInterval);
    }

    scrollHelper.scrollerInterval = setInterval(() => {
      const lastMGroup: HTMLElement | null = window.document.querySelector(".cs-message-group:last-of-type");
      if (lastMGroup) {
        // console.log("shes");
        if (scrollHelper.canScroll) {
          lastMGroup.scrollIntoView(true);
          // ppp.scrollTop += 1;
        } else {
          if (scrollHelper.scrollerInterval) {
            window.clearInterval(scrollHelper.scrollerInterval);
            scrollHelper.canScroll = true;
          }
        }
      }
    }, 2000);

    if (scrollHelper.stopperTimout) {
      window.clearTimeout(scrollHelper.stopperTimout);
    }

    scrollHelper.stopperTimout = setTimeout(() => {
      scrollHelper.canScroll = false;
    }, 2000 * (messageCountInLastGroup + 0.5));
  }, 200);
}

const ChatMessageGroup: React.FC<IMessageGroup> = (props) => {
  const {caseBlockMaker, statusInfo, isBeforeLastVedict} = props;
  const avatar = props.avatar === 'bot' ? ChatBotIcon : props.avatar;
  const messageCount = props.data.length;

  useEffect(() => {
    scrollChatDown(messageCount);

    return () => {
      scrollHelper.canScroll = false;
      if (scrollHelper.scrollerInterval) {
        window.clearInterval(scrollHelper.scrollerInterval);
        scrollHelper.scrollerInterval = undefined;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   hideAllPreviousBotAvatarsInChat();
  // });

  return (
    <MessageGroup
      direction={props.direction}
      className={cx(chatStyles.messageGroup, props.className, mGStyles.messageGroup, mGStyles[props.direction])}
    >
      {!!avatar && <Avatar src={avatar} className={cx(styles.avatar, mGStyles.avatar)} />}

      <MessageGroup.Messages className={mGStyles.groupOfMessages}>
        {props.data.map(
          ({ message, render, className, renderClassName, status, ind, messageClassName }, idx) => {
            let shortcutLink: null | JSX.Element = null;
            let separator: null | JSX.Element | string = null;

            if (caseBlockMaker && typeof isBeforeLastVedict === "boolean") {
              if (status === CLAIM_STATUSES.SOLUTION_PICKUP_PENDING && ind === 2) {
                shortcutLink = caseBlockMaker({shortcutName: "repair", statusInfo, isBeforeLastVedict});
                separator = ' ';
              } else if (status && status.includes(CLAIM_STATUSES.SOLUTION_REFUND_PENDING)) {
                shortcutLink = caseBlockMaker({shortcutName: "refundPending", statusInfo, isBeforeLastVedict});
                separator = ' ';
              } else if (status === CLAIM_STATUSES.ASSESSMENT_SELECTION) {
                shortcutLink = caseBlockMaker({shortcutName: "voucher", statusInfo, isBeforeLastVedict});
                separator = ' ';
              }
            }

            return (
              <Message
                key={idx}
                className={cx(chatStyles.message, className, renderClassName, mGStyles[props.direction], mGStyles.message)}
                model={{
                  direction: props.direction,
                  position: !idx ? 'first' : undefined,
                  ...(render ? {} : { message: Array.isArray(message) ? message.join('') : message }),
                  
                }}
              ><Message.CustomContent className={messageClassName}>
                <div>
                  {render ? (
                    <Message.CustomContent className={mGStyles.text}>{render()}</Message.CustomContent>
                  ) : (
                    <Message.CustomContent className={mGStyles.text}>
                      {message}
                      {separator}
                      {shortcutLink}
                    </Message.CustomContent>
                  )}
                </div>
                
                {props.direction === "incoming" && <ChatBotAnimatedDots mgClass={mGStyles.dotsAni} />}
                
                </Message.CustomContent>
              </Message>
            );
          },
        )}
        {/* {props.direction === "incoming" && <ChatBotAnimatedDots mgClass={mGStyles.dotsAni} />} */}
        {/* // LeoAni important */}
      </MessageGroup.Messages>
    </MessageGroup>
  );
};

export default ChatMessageGroup;
