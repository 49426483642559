import { Layout, RegistrationForm } from 'Components';
import { useSelector } from 'react-redux';

import type { StateT as UserStateT } from 'Store/modules/user';
import type { AppStateT } from 'Store/modules';

export default function Registration() {
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const { email } = useSelector<AppStateT, Pick<UserStateT, 'email' | 'lastName' | 'title' | 'firstName'>>(
        ({ user }) => ({
            email: user.email,
            lastName: user.lastName,
            firstName: user.firstName,
            title: user.title,
        }),
    );

    return (
        <Layout back>
            <RegistrationForm email={email?.replace(' ', '').toLowerCase()} actualLanguage={actualLanguage} />
        </Layout>
    );
}
