import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ChatMessageGroup, { IMessage } from 'Components/ChatMessageGroup/ChatMessageGroup';
import ClaimBlockedOnChat from "Components/ClaimBlockedOnChat/ClaimBlockedOnChat";
import { ClaimHistoryT } from 'api/modules/case-resolution';
import { useMobile } from 'Helpers/responsiveContainers';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { PageConfigT } from 'Store/modules/page';
import { ClaimT } from 'api/modules/claim';
import { prepareUrl } from 'Helpers/claim';
import { AppStateT } from 'Store/modules';


type PropsMainT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT,
}

const SparePartsResolution: React.FC<PropsMainT> = ({ claim, statusInfo }) => {

    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const { t } = useTranslation();
    const isMobile = useMobile();

    const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies)

    useEffect(() => {

        const assessmentResult = claim?.assessmentResults?.find(a => a.isActive);
        if (assessmentResult && (assessmentResult.resolution.voucher || assessmentResult.value)) {

            const _m: IMessage[] = [
                { render: () => (
                    <>
                        {parse(t('chatMessages.moneyCompensationResultLabel', {
                            price: `<span class="price">${formatPrice(assessmentResult.resolution.voucher || assessmentResult.value)} ${claim.currencyIsoCode}</span>`,
                            url: `<a href="${prepareUrl(claim, isMobile)}" class="linkChat">${t('common.link')}</a>` 
                        }))}
                    </>
                ) },
            ];
    
            setMessages(_m);
        } else {
            setMessages([])
        }

    }, [t, claim.currencyIsoCode, claim?.assessmentResults, isMobile, claim, formatPrice]);

    if (messages.length === 0){
        return <></>;
    }

    if (!claim.assessmentResults?.find(r => r.isActive && !!r.resolution?.moneyCompensationUrl)){
        return <ClaimBlockedOnChat claim={claim} reason={t('chatMessages.sparePartsNoUrl')} />
    }

    return (
        <ChatMessageGroup
            data={messages}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        />
    );
}
export default SparePartsResolution;