import React, { useState, useEffect, useRef } from 'react';
import styles from './HamburgerMenu.module.scss';
import WrapperLink from 'Components/Footer/WrapperLink/WrapperLink';
import { ReactComponent as Logo } from 'Assets/logo.svg';

interface HamburgerMenuProps {
    className?: string;
    menuItems: { name: string, path: string }[];
    onMenuItemClick: (e: React.MouseEvent, type: string) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ menuItems, onMenuItemClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isIconTransitioning, setIsIconTransitioning] = useState(false);

    const timeouts = useRef<any[]>([]); 

    const clearAllTimeouts = () => {
        timeouts.current.forEach(timeout => clearTimeout(timeout));
        timeouts.current = [];
    };

    const renderHamburgerIcon = () => (
        <>
            <div className={styles.hamburgerMenuLines}></div>
            <div className={styles.hamburgerMenuLines}></div>
            <div className={styles.hamburgerMenuLines}></div>
        </>
    );
    
    const renderCloseIcon = () => (
        <div className={styles.closeIcon}>✖</div>
    );

    const toggleMenu = () => {
        if (isMenuOpen) {
            setIsIconTransitioning(true);
            const iconTimeout = setTimeout(() => {
                setIsClosing(true);
                const closeTimeout = setTimeout(() => {
                    setIsMenuOpen(false);
                    setIsClosing(false);setIsClosing(false);
                    setIsOverlayVisible(false);
                    setIsIconTransitioning(false);
                }, 300);
                timeouts.current.push(closeTimeout); 
            }, 300);
            timeouts.current.push(iconTimeout); 
        } else {
            setIsMenuOpen(true);
            setIsOverlayVisible(true);
            setIsIconTransitioning(false);
        }
    };

    useEffect(() => {
        return () => {
            clearAllTimeouts(); 
        };
    }, []);

    return (
        <>
            <div className={`${styles.hamburgerMenu} ${isIconTransitioning ? styles.transitioning : ''}`} onClick={toggleMenu}>
                {isMenuOpen ? renderCloseIcon() : renderHamburgerIcon()}
            </div>
            
            {isOverlayVisible && (
                <div 
                    className={`${styles.overlay} ${isClosing ? styles.closing : ''}`} 
                    onClick={toggleMenu}
                ></div>
            )}
    
            <div className={`${styles.menu} ${isMenuOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                {menuItems.map(({ name, path }) => (
                    <div
                        className={styles.menuItem}
                        key={`menu3_${name}`}
                        onClick={toggleMenu}
                    >
                        <WrapperLink name={name} path={path} actionUrlClick={onMenuItemClick} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default HamburgerMenu;
