import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { MessageList } from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import get from 'lodash.get';
import cx from 'classnames';

import { ReactComponent as LuggageIcon } from 'Assets/luggage.svg';
import { ReactComponent as RepairIcon } from 'Assets/repair.svg';
import { ReactComponent as IcoInfo } from 'Assets/info-btn.svg';
import { ReactComponent as CashIcon } from 'Assets/cash.svg';

import { getClosestWorkingDay, getSecondsActual, secondsSince, getAgeName, calculateDateDifference } from 'Helpers/date';
import AssessmentProcessBlock from 'PageComponents/Chat/CustomChatBlocks/AssessmentProcessBlock/AssessmentProcessBlock';
import UserResolutionBlock from 'PageComponents/Chat/CustomChatBlocks/UserResolutionBlock/UserResolutionBlock';
import { setChatMessages, socketsSuccessAutomaticResolution } from 'Store/modules/claimChat/data';
import { getVoucherValue } from '../CustomChatBlocks/CaseResolutionBlock/CaseResolutionBlock';
import { MissingDocExampleT, StateT, setMissingDocumentExamples } from 'Store/modules/claim';
import { markClaimToResolve as markClaimToResolveAction } from 'Store/modules/markedClaims';
import ReportBlock from 'PageComponents/Chat/CustomChatBlocks/ReportBlock/ReportBlock';
import { ClaimHistoryT, IMyResolution } from '../../../api/modules/case-resolution';
import ClaimBlockedOnChat from "Components/ClaimBlockedOnChat/ClaimBlockedOnChat";
import ChatAssessmentPending from "./ChatAssessmentPending/ChatAssessmentPending";
import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import MessageChatMain from "PageComponents/MessageChatMain/MessageChatMain";
import { StateT as ClaimDetailsStateT } from 'Store/modules/claimDetails';
import VerdictBlock from '../CustomChatBlocks/VerdictBlock/VerdictBlock';
import { reload as actionReload } from "Store/modules/claimChat/data";
import { getUrlShopToken, ReturnShopUrlTokenT } from "services/shop";
import RepairPickUpForm from "../RepairPickUpForm/RepairPickUpForm";
import { sendReportRequest } from 'Store/modules/claimChat/report';
import ContactBranch from 'Components/ContactBranch/ContactBranch';
import { chatAutomaticResolutionWaitSeconds } from "Helpers/rules";
import ChatGoogleReview from "./ChatGoogleReview/ChatGoogleReview";
import type { StateT as UserStateT } from 'Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import useReportTagTranslation from 'Hooks/useOriginalTags';
import { getExamplesMissingDocuments } from "services/api";
import { ClaimChatStateT } from "Store/modules/claimChat";
import LoadingCss from "Components/LoadingCss/LoadingCss";
import runApi, { ApiSendMethods } from "api/base/runApi";
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { ClaimT, VerdictT } from 'api/modules/claim';
import { CatalogsE } from "Store/Enums/urlsCatalogs";
import useValueVoucher from "Hooks/useValueVoucher";
import { RESOLUTION_TYPE } from './Chat.constants';
import { CLAIM_STATUSES } from 'Config/statuses';
import { PageConfigT } from 'Store/modules/page';
import type { AppStateT } from 'Store/modules';
import { useTranslation } from 'react-i18next';
import historyStatus from 'Hooks/useHistory';
import { Loader, Modal } from 'Components';
import { prepareUrl } from "Helpers/claim";
import styles from './Chat.module.scss';

export type nun = null | undefined;

export interface IPossibleResolutions {
  repair: boolean;
  refund: number | false;
  voucher: number | false;
}

export interface IOneResolutionOption {
  title: string;
  description: string;
  icon: JSX.Element | (() => JSX.Element);
  onClick: () => void;
  disabled: boolean;
  info?: (evt: React.MouseEvent<HTMLElement>) => void;
  tooltipKey?: string
}

export type TyShortcutSpanMaker = ({
  shortcutName,
  statusInfo,
  isBeforeLastVedict,
}: {
  shortcutName?: tyShortcutToRes;
  statusInfo?: ClaimHistoryT | null;
  isBeforeLastVedict: boolean;
}) => JSX.Element | null;

export type tyShortcutToRes = 'refund' | 'refundPending' | 'repair' | 'voucher';
export type tySelectedRes = 'cashRefund' | 'shop' | 'pick-up';

export const selectedResolutionType: { [key: string]: string } = {
  [CLAIM_STATUSES.SOLUTION_REFUND_PENDING]: 'cashRefund',
  [CLAIM_STATUSES.SOLUTION_PENDING_ORDER]: 'shop',
  [CLAIM_STATUSES.SOLUTION_PICKUP_PROCESS]: 'pick-up',
};

interface IChat {
  onChooseResolution?: (resolutionType: RESOLUTION_TYPE) => void;
  claimId: string;
  openFromMobile?: boolean,
  claim?: ClaimT | undefined,
  actionParam?: string,
  openStore: () => void
}

interface IUserSelector {
  user: UserStateT['data'];
}

function usePageData(
  claim: ChatDataStateT['claim'],
  verdict: ChatDataStateT['verdict'],
  resolution: ChatDataStateT['resolution'],
  loading: ChatDataStateT['loading'],
) {
  const dispatch = useDispatch();
  // Selectors

  const claimMarked = useSelector<AppStateT>(({ markedClaims }) =>
    claim ? markedClaims[claim.id] : null,
  );

  const savedRate = useSelector<AppStateT>(({ savedRating }) =>
    claim ? savedRating[claim.id] : 0,
  ) as string | number | nun;
  // Internal state
  const hasUserResolution = Boolean(resolution);
  const [caseResolutionModal, setCaseResolutionModal] = useState<null | string>(null);
  const showStartResolutionButton =
    claim && verdict && !(claimMarked || claim?.claimStatus?.[0].status !== 'ASSESSMENT_DECISION');

  // Handlers
  const markClaimToResolve = () => {
    if (claim) {
      dispatch(markClaimToResolveAction(claim.id));
    }
  };

  return {
    // Internal state
    hasUserResolution,
    caseResolutionModal,
    setCaseResolutionModal,
    showStartResolutionButton,
    // Selectors
    claim,
    verdict,
    resolution,
    loading,
    savedRate,
    claimMarked,
    // Handlers
    markClaimToResolve,
  };
}

export interface IFAQModalData {
  value: string;
  resolution: RESOLUTION_TYPE | null;
}

let timeoutSocketAutoamticVerdict: any = null;
let timeoutScroll: any = null;

const statusesNotToRepeat: CLAIM_STATUSES[] = [CLAIM_STATUSES.ASSESSMENT_PROCESS];

export default function Chat({ openStore, claimId, onChooseResolution = () => { }, openFromMobile, claim: claimProps, actionParam }: IChat) {
  const { t } = useTranslation();
  const [isReportedOriginal, setIsReportedOriginal] = useState(false);
  const { claim, resolution, loading, reload, websocketChatAddMessage, successAutomaticResolution } = useSelector<
    AppStateT,
    Pick<ChatDataStateT, 'claim' | 'resolution' | 'loading' | 'claimHistory' | 'reload' | 'websocketChatAddMessage' | 'successAutomaticResolution'>
  >(({ claimChat }) => ({
    claim: claimChat.data.claim,
    resolution: claimChat.data.resolution,
    loading: claimChat.data.loading,
    claimHistory: claimChat.data.claimHistory,
    reload: claimChat.data.reload,
    websocketChatAddMessage: claimChat.data.websocketChatAddMessage,
    successAutomaticResolution: claimChat.data.successAutomaticResolution
  }));
  const { user } = useSelector<AppStateT, IUserSelector>(({ user }) => ({ user: user.data }));
  const { loading: claimLoading } = useSelector<AppStateT, ClaimDetailsStateT>(({ claimDetails }) => claimDetails);
  const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
  const [isWaitingOriginal, setIsWaitingOriginal] = useState(false);
  const [readMessageChat, setReadMessageChat] = useState(0);
  const { repair } = useSelector<AppStateT, ClaimChatStateT>(({ claimChat }) => claimChat);
  const repairLocation = repair.userLocation || claim?.location;
  const [showWaitingAutomatic, setShowWaitingAutomatic] = useState<number>(0);
  const { isNotMobile, isMobile } = useDeviceType();
  const navigate = useNavigate();
  const bottomRef = useRef(null);
  const getTitle = useReportTagTranslation(t);
  const claimHistory = historyStatus(claim);
  const [missingDocsExamples, setMissingDocsExamples] = useState<MissingDocExampleT[]>([]);
  const [generatingUrl, setGereratingUrl] = useState<CatalogsE | null>(null);

  const claimMarked = useSelector<AppStateT>(({ markedClaims }) =>
    claim ? markedClaims[claim.id] : null,
  );
  const { missingDocumentExamples } = useSelector<AppStateT, StateT>(({ claim }) => claim);
  const dispatch = useDispatch();
  const showStartResolutionButton = claim && claim?.verdict && !claimMarked && !claim.orders;
  const rabatData = useValueVoucher(claim, pageConfig.airlinesWithRabatVoucher);

  /*
   * Read example documents,
   * It is hack provide doubled reading by doubled rendered
   * This state value: missingDocsExamples is not nessessary, exist becouse this chat is wrong rendered
   **/
  useEffect(() => {
    if (claim?.id) {
      if (!missingDocumentExamples.some(ex => ex.claimId === claim.id)) {
        getExamplesMissingDocuments(claim.id, (result: boolean, data?: MissingDocExampleT[]) => {
          if (result && data) {
            setMissingDocsExamples(data);
            dispatch(setMissingDocumentExamples(claim.id, data));
          } else {
            setMissingDocsExamples([]);
          }
        });
      } else {
        setMissingDocsExamples(missingDocumentExamples.find(ex => ex.claimId === claim.id)?.missingDocExamples || []);
      }
    }
  }, [claim?.id, dispatch, missingDocumentExamples, reload]);

  useLayoutEffect(() => {
    clearTimeout(timeoutScroll);
    if (bottomRef) {
      setTimeout(() => {
        if (claim?.id) {
          bottomRef.current?.scrollIntoView?.({ behavior: 'smooth' });
        }
      }, 1200);
      return () => {
        clearTimeout(timeoutScroll);
      }
    }
  }, [claim?.id, bottomRef]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutSocketAutoamticVerdict);
    }
  }, []);

  useEffect(() => {
    if (claim?.createdAt && actionParam === 'auto') {
      if (claim?.statusHistory && Array.isArray(claim.statusHistory) && claim.statusHistory[claim.statusHistory.length - 1].status === CLAIM_STATUSES.ASSESSMENT_PENDING) {
        navigate("/" + claim.id);
        return;
      }
      const seconds = secondsSince(claim.createdAt);
      if (seconds < chatAutomaticResolutionWaitSeconds) {
        setShowWaitingAutomatic(chatAutomaticResolutionWaitSeconds - seconds);
      } else {
        setShowWaitingAutomatic(0);
      }
    } else {
      setShowWaitingAutomatic(0);
    }
  }, [actionParam, claim?.createdAt, claim?.statusHistory, claim?.id, navigate]);

  useEffect(() => {
    if (successAutomaticResolution && claim?.id) {
      setShowWaitingAutomatic(0);
      navigate("/" + claim.id);
      dispatch(actionReload());
      timeoutSocketAutoamticVerdict = setTimeout(() => {
        dispatch(socketsSuccessAutomaticResolution(false));
      }, 500);
    }
  }, [successAutomaticResolution, dispatch, navigate, claim?.id]);

  useEffect(() => {
    if (claim && claim.reports && !claim?.verdict && claim.reports[claim.reports?.length - 1]?.tags && claim.reports[claim.reports?.length - 1]?.tags.every(t => t.value.indexOf('_ORIGINAL') >= 0)
      && claim.reports[claim.reports?.length - 1].tags?.length !== claim.reports[claim.reports?.length - 1].resolution?.length) {
      setIsReportedOriginal(true);
    } else {
      setIsReportedOriginal(false)
    }
  }, [claim]);

  useEffect(() => {
    if (claim && !claim?.verdict && claim.status === CLAIM_STATUSES.VALIDATION_PAX && claim.requiredDocuments && Array.isArray(claim.requiredDocuments) && claim.requiredDocuments?.length > 0
      && claim.requiredDocuments.some(doc => doc.isOriginal__c)) {
      setIsWaitingOriginal(true);
    } else {
      setIsWaitingOriginal(false);
    }
  }, [claim]);

  const formatPrice = useFormattedPrice(claim?.currencyIsoCode, pageConfig.noFractionCurrencies)

  const {
    // hasUserResolution,
    setCaseResolutionModal,
    caseResolutionModal
    // showStartResolutionButton,
    // markClaimToResolve,
    // claimMarked,
  } = usePageData(claim, claim?.verdict, resolution, loading);

  // useEffect(() => {
  //   if (!claimProps && !claimList.loading && claimList.data.some(d => d.id === claimId)){
  //       dispatch(openClaimChat(claimId, claimsArchived));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, claimId, claimProps, reload, claimsArchived, claimList.loading]);

  /*
   * Read chat-messages from client
   **/
  useEffect(() => {
    if (claim) {
      runApi(`claim/${claim?.id}/chat`, {}, (r: any) => {
        if (r.result && r.data) {
          dispatch(setChatMessages(r.data));
        } else {
          dispatch(setChatMessages(null));
        }
      }, ApiSendMethods.get);
    }
  }, [claim, dispatch, readMessageChat]);

  useEffect(() => {
    if (claim && websocketChatAddMessage && websocketChatAddMessage.claimId === claim.id) {
      setReadMessageChat(old => old + 1);
    }
  }, [websocketChatAddMessage, claim]);

  useEffect(() => {
    if (resolution) {
      onChooseResolution(RESOLUTION_TYPE.NONE);
      setCaseResolutionModal(RESOLUTION_TYPE.NONE);
    }
  }, [resolution, onChooseResolution, setCaseResolutionModal]);

  const isAssessmentTimeOut = useMemo(() => get(claim, 'isAssessmentTimeOut') || false, [claim]);
  const assessmentCreatedAt = useMemo(() => claim?.assessmentCreatedAt || new Date().toISOString(), [claim]);

  const actionGetUrlShop = useCallback((catalog: CatalogsE) => {
    setGereratingUrl(catalog);
    getUrlShopToken(catalog, claim?.id, dispatch, (result: boolean, data: ReturnShopUrlTokenT) => {
      if (!data.url) {
        openStore();
      }
      setGereratingUrl(null);
    });
  }, [claim?.id, dispatch, openStore])

  const showLoader = useMemo(() => {
    return (
      (claimHistory &&
        claimHistory[claimHistory?.length - 1]?.status?.status === 'ASSESSMENT_PROCESS' &&
        claimHistory.filter((h) => h?.status?.status === 'ASSESSMENT_PROCESS')?.length === 1 &&
        !isAssessmentTimeOut) ||
      false
    );
  }, [claimHistory, isAssessmentTimeOut]);

  const generateAllOptionsForPossibleResolutions = useCallback(
    ({
      possibleResolutions,
      statusInfo,
      // hasLastVerdict,
      isBeforeLastVedict,
    }: {
      possibleResolutions?: IPossibleResolutions | null;
      statusInfo?: ClaimHistoryT;
      hasLastVerdict?: boolean;
      isBeforeLastVedict: boolean;
    }) => {
      // const entirelyDisabled = !statusInfo?.verdict?.isActive;
      // const entirelyDisabled = !hasLastVerdict;
      const entirelyDisabled = isBeforeLastVedict;
      const verRes = statusInfo?.verdict?.resolution;
      const getStoreDescription = () => {
        if (claim?.verdict?.resolution?.discountCode && claim.verdict?.resolution?.voucher === 0) {
          return t('resolutionOptions.storeDescriptionCode', { discountCode: claim.verdict.resolution.discountCode })
        } else {
          return t('resolutionOptions.storeDescription', {
            currency: claim?.currencyIsoCode || 'EUR',
            value: `${formatPrice(rabatData.voucher)}` || getVoucherValue(possibleResolutions?.voucher),
          })
        }
      }
      const all: IOneResolutionOption[] = [
        {
          title: t('resolutionOptions.cashRefundTitle'),
          description: t('resolutionOptions.cashRefundDescription', {
            currency: '€',
            value: verRes?.refund || getVoucherValue(possibleResolutions?.refund),
          }),
          icon: () => <CashIcon />,
          onClick: () =>
            isNotMobile
              ? onChooseResolution(RESOLUTION_TYPE.CASH_REFUND)
              : setCaseResolutionModal(RESOLUTION_TYPE.CASH_REFUND),
          disabled: entirelyDisabled ? true : !Boolean(possibleResolutions?.refund),
        },
        {
          title: t('resolutionOptions.storeTitle'),
          description: getStoreDescription(),
          onClick: () => !generatingUrl ? actionGetUrlShop(CatalogsE.MAGENTO) : null,

          icon: generatingUrl ? <LoadingCss /> : <LuggageIcon />,
          disabled: entirelyDisabled ? true : !Boolean(possibleResolutions?.voucher === 0 || possibleResolutions?.voucher),
        },
        {
          title: t('resolutionOptions.repairTitle'),
          description: t('resolutionOptions.repairDescription'),
          icon: <RepairIcon />,
          onClick: () =>
            isNotMobile
              ? onChooseResolution(RESOLUTION_TYPE.REPAIR)
              : setCaseResolutionModal(RESOLUTION_TYPE.REPAIR),
          disabled: entirelyDisabled ? true : !possibleResolutions?.repair,
        },
      ];

      return all;
    },
    [isNotMobile, onChooseResolution, setCaseResolutionModal, t, claim, formatPrice, rabatData, actionGetUrlShop, generatingUrl],
  );

  const legitPossibleResolutions: IPossibleResolutions | nun = useMemo(() => {
    if (!claimHistory || claimHistory?.length === 0) {
      return undefined;
    }

    let indexOfLastTruthyPossibleResolutions: number = -1;
    let indexOfLastTruthySelectedResolution: number = -1;
    let indexOfLastTruthyResolutionByUser: number = -1;

    for (let i = claimHistory.length - 1; i >= 0; i -= 1) {
      const curr = claimHistory[i];
      if (curr.verdict?.resolution) {
        indexOfLastTruthyPossibleResolutions = i;
        break;
      }
    }

    for (let i = claimHistory.length - 1; i >= 0; i -= 1) {
      const curr = claimHistory[i];
      if (curr.verdict?.selectedResolution) {
        indexOfLastTruthySelectedResolution = i;
        break;
      }
    }

    for (let i = claimHistory.length - 1; i >= 0; i -= 1) {
      const curr = claimHistory[i];
      if (curr.resolution) {
        indexOfLastTruthyResolutionByUser = i;
        break;
      }
    }

    //

    const analizerArray: {
      kind: 'possibleResolutions' | 'selectedResolution' | 'resolutionByUser';
      indexInHist: number;
      objectItself: any;
    }[] = [];

    if (indexOfLastTruthyPossibleResolutions >= 0) {
      analizerArray.push({
        kind: 'possibleResolutions',
        indexInHist: indexOfLastTruthyPossibleResolutions,
        objectItself: claimHistory[indexOfLastTruthyPossibleResolutions].verdict.resolution,
      });
    }

    if (indexOfLastTruthySelectedResolution >= 0) {
      analizerArray.push({
        kind: 'selectedResolution',
        indexInHist: indexOfLastTruthySelectedResolution,
        objectItself: claimHistory[indexOfLastTruthySelectedResolution].verdict.selectedResolution,
      });
    }

    if (indexOfLastTruthyResolutionByUser >= 0) {
      analizerArray.push({
        kind: 'resolutionByUser',
        indexInHist: indexOfLastTruthyResolutionByUser,
        objectItself: claimHistory[indexOfLastTruthyResolutionByUser].resolution,
      });
    }

    if (analizerArray.length === 0) {
      return undefined;
    }

    analizerArray.sort((a, b) => {
      if (a.indexInHist > b.indexInHist) {
        return -1;
      } else if (a.indexInHist < b.indexInHist) {
        return 1;
      } else if (a.kind === 'possibleResolutions' && b.kind === 'selectedResolution') {
        return 1;
      } else if (a.kind === 'selectedResolution' && b.kind === 'possibleResolutions') {
        return -1;
      }

      return 0;
    }); // to see chronological order

    const lastVerdict = claimHistory[indexOfLastTruthyPossibleResolutions]?.verdict.resolution;
    let finalPossibleResolutions: IPossibleResolutions = lastVerdict || {
      repair: false,
      refund: false,
      voucher: false,
    };

    const winner = analizerArray[0];

    if (winner.kind === 'possibleResolutions') {
      finalPossibleResolutions = winner.objectItself as IPossibleResolutions;
    } else if (winner.kind === 'selectedResolution') {
      const obj = winner.objectItself as tySelectedRes;
      if (obj === 'cashRefund') {
        finalPossibleResolutions = {
          repair: false,
          refund: lastVerdict?.refund || 1,
          voucher: false,
        };
      } else if (obj === 'pick-up') {
        finalPossibleResolutions = { repair: true, refund: false, voucher: false };
      } else if (obj === 'shop') {
        finalPossibleResolutions = {
          repair: false,
          refund: false,
          voucher: lastVerdict?.voucher || 1,
        };
      }
    } else if (winner.kind === 'resolutionByUser') {
      finalPossibleResolutions = { repair: false, refund: false, voucher: false };
    } else {
      //
    }

    return finalPossibleResolutions;
  }, [claimHistory]);

  const lastResolution: IMyResolution | nun = useMemo(() => {
    if (!claimHistory || claimHistory.length === 0) {
      return undefined;
    }
    let indexOfLastTruthyResolutionByUser: number = -1;

    for (let i = claimHistory.length - 1; i >= 0; i -= 1) {
      const curr = claimHistory[i];
      if (curr.resolution) {
        indexOfLastTruthyResolutionByUser = i;
        break;
      }
    }

    let myValue: IMyResolution | nun = undefined;

    if (indexOfLastTruthyResolutionByUser >= 0) {
      myValue = claimHistory[indexOfLastTruthyResolutionByUser].resolution;
    }

    return myValue;
  }, [claimHistory]);

  const firstVerdict: VerdictT | nun = useMemo(() => {
    if (!claimHistory || claimHistory.length === 0) {
      return undefined;
    }

    const firstVerd = claimHistory.find((x) => x?.verdict?.resolution)?.verdict;

    return firstVerd;
  }, [claimHistory]);

  // console.log('claimHistory: ', claimHistory);
  const claimStatusList = useMemo(() => {
    if (!claimHistory || claimHistory.length === 0) {
      return undefined;
    }
    // const indexWhereClaimHistoryHasFirstResolution = claimHistory?.findIndex((x) => !!x.resolution);
    const indexFirstAssessment = claimHistory?.findIndex(
      (x) => x.status.status === 'ASSESSMENT_PROCESS',
    );

    let indexOfLastVerdict = -1;
    const realHistory = claimHistory?.filter((data: ClaimHistoryT, idx: number, obj: any) => !(idx > 0 && obj[idx - 1]?.status?.status === data.status.status));
    // const realHistory = claimHistory?.filter((data: ClaimHistoryT, idx: number, obj: any) => !claimHistory.some((d, idx2) => idx2 > idx && d.status.status !== data.status.status));
    for (let i = realHistory.length - 1; i >= 0; i -= 1) {
      if (realHistory[i].verdict) {
        indexOfLastVerdict = i;
        break;
      }
    }

    const _claimHistory = realHistory;

    if (!_claimHistory) return undefined;
    const statusShowd = [];

    return realHistory?.map((statusInfo, i) => {
      // const showRateBox = i === indexWhereClaimHistoryHasFirstResolution;
      const showAssessmentTimeOut = i === indexFirstAssessment || (realHistory && realHistory.length > 0 ? getSecondsActual(realHistory[realHistory?.length - 1].status.createdAt) >= 60 : false);

      const { status, report, verdict } = statusInfo;

      let firstTimeStatusShow = false;
      if (!statusShowd.includes(statusInfo.status.status)) {
        statusShowd.push(statusInfo.status.status);
        firstTimeStatusShow = true;
      }

      const verRes = verdict?.resolution;

      if (verRes?.voucher && typeof verRes.voucher === 'number') {
        verRes.voucher = +formatPrice(+verRes.voucher);
      }

      if (verRes?.refund && typeof verRes.refund === 'number') {
        verRes.refund = +formatPrice(+verRes.refund);
      }

      const diffData = calculateDateDifference(claim?.verdict?.purchaseDate, claim?.verdict?.assessmentCreatedAt);

      const hasLastVerdict = i === indexOfLastVerdict;
      const isBeforeLastVedict = i < indexOfLastVerdict;

      const resOptionsForHistCurrStatus = generateAllOptionsForPossibleResolutions({
        possibleResolutions: legitPossibleResolutions,
        statusInfo,
        hasLastVerdict,
        isBeforeLastVedict,
      });

      // brakuje na endpoicie GET /claim/{claimId} pola verdict po wysłaniu pickup'a

      const renderStatus = () => {

        if (claim?.statusHistory && claim.statusHistory.length === 1) {
          return (
            <AssessmentProcessBlock
              key={`ass_${status.status}_${i}`}
              showLoader={showLoader}
              assessmentCreatedAt={assessmentCreatedAt}
              isAssessmentTimeOut={isAssessmentTimeOut}
              statusInfo={statusInfo}
              showAssessmentTimeOut={showAssessmentTimeOut}
            />
          );
        }

        switch (status.status) {
          case 'ASSESSMENT_PENDING':
            return claim?.statusHistory && claim?.statusHistory[claim?.statusHistory.length - 1].status === CLAIM_STATUSES.ASSESSMENT_PENDING ? (
              <ChatAssessmentPending
                t={t}
                assessmentCreatedAt={assessmentCreatedAt}
              />
            ) : null;
          case 'ASSESSMENT_PROCESS':
            return (
              <AssessmentProcessBlock
                key={`assb_${status.status}_${i}`}
                showLoader={showLoader}
                assessmentCreatedAt={assessmentCreatedAt}
                isAssessmentTimeOut={isAssessmentTimeOut}
                statusInfo={statusInfo}
                showAssessmentTimeOut={showAssessmentTimeOut}
              />
            );

          default:
            return null;
        }
      };

      return (
        <React.Fragment key={`${statusInfo.status.id}_${i}_${claim?.catalogs ? claim.catalogs.length : ''}`}>
          {((firstTimeStatusShow && statusesNotToRepeat.includes(statusInfo.status.status)) || !statusesNotToRepeat.includes(statusInfo.status.status)) &&
            <MessageChatMain
              status={statusInfo.status.status}
              data={{
                voucher: verdict?.resolution?.voucher || 0,
                residualValue: verdict?.resolution?.residualValue || 0,
                templateValue: verdict?.resolution?.refund || 0,
                baggageAge: getAgeName((diffData.years * 12) + diffData.months, t),
                currencyIsoCode: claim?.currencyIsoCode,
                airlineName: claim?.airlineName,
                urlForm: prepareUrl(claim, isMobile),
                urlGoogleReview: claim?.case?.review?.googleMapsUrl,
                urlTrustPilot: claim?.case?.review?.trustPilotUrl,
                pickupData: claim?.resolutions && Array.isArray(claim.resolutions) ? ` <strong>${claim.resolutions.find(r => r.status === 'success')?.resolutionData?.date}</strong>` || '' : '',
                resolution: claim?.resolutions && Array.isArray(claim.resolutions) && claim.resolutions.length > 0 ? claim.resolutions.find(res => res.claimStatusId === statusInfo.status.id) : undefined
              }}
              shipments={claim?.shipments}
            />
          }
          {renderStatus()}
          {(firstTimeStatusShow && statusInfo.status.status === CLAIM_STATUSES.SHIPMENT_OUT_FOR_DELIVERY && claim?.case?.review && (!!claim.case?.review?.googleMapsUrl || !!claim.case?.review?.trustPilotUrl)) &&
            <ChatGoogleReview claimId={claim.id} review={claim.case?.review} />
          }
          {!!verdict && !verdict.selectedResolution &&
            ((verdict.isSelected && !verdict.isActive) || (!verdict.isSelected && verdict.isActive)) && (
              <VerdictBlock
                key={`verdict-${status.status}_${claim?.catalogs ? claim.catalogs.length : ''}`}
                verdict={verdict}
                onChooseResolution={onChooseResolution}
                setCaseResolutionModal={setCaseResolutionModal}
                openStore={openStore}
                optionsForHistCurrResolution={resOptionsForHistCurrStatus}
                statusInfo={statusInfo}
                lastResolution={lastResolution}
                firstVerdict={firstVerdict}
                t={t}
              />
            )}

          {/* show data after verdict  */}
          {(!(!!claim?.catalogs && claim.catalogs.length > 0 && status.status !== CLAIM_STATUSES.VALIDATION_PAX) && (!claim?.orders || (claim.orders && claim.orders.length === 0)) && !showStartResolutionButton) &&
            <>
              {(!!report && !claim?.isBlocked && !(!report.resolution && report.isResolved && !claim?.closed)) && (
                <ReportBlock
                  key={`report-${report?.id}_${i}`}
                  report={report}
                  onSubmit={(data) => {
                    dispatch(sendReportRequest({ report, data }));
                  }}
                  requredDocuments={claim?.requiredDocuments}
                  missingDocsExamples={missingDocsExamples}
                />
              )}
              <UserResolutionBlock
                as="MessageGroup"
                isNotMobile={isNotMobile}
                onChooseResolution={onChooseResolution}
                setCaseResolutionModal={setCaseResolutionModal}
                statusInfo={statusInfo}
              />
              {/* {((!!possibleResolutions || !!chosenResolution || !!legitPossibleResolutions) && showAfterLines) && ( */}
            </>
          }
        </React.Fragment>
      );
    });
  }, [
    dispatch,
    claimHistory,
    assessmentCreatedAt,
    isAssessmentTimeOut,
    onChooseResolution,
    openStore,
    setCaseResolutionModal,
    showLoader,
    // rateMessageMaker,
    generateAllOptionsForPossibleResolutions,
    legitPossibleResolutions,
    lastResolution,
    firstVerdict,
    isNotMobile,
    t,
    showStartResolutionButton,
    formatPrice,
    missingDocsExamples,
    claim,
    isMobile,
  ]);

  if (showWaitingAutomatic > 0) {
    return <div className={cx(styles.waitingAutoverdict, { [styles.mobile]: isMobile })}>
      <p>{t('chatMessages.waitingAutoVerdict')}</p>
      <CountdownCircleTimer
        isPlaying
        duration={showWaitingAutomatic}
        colors={'#2fc3dd'}
        size={50}
        strokeWidth={2}
        onComplete={() => {
          setShowWaitingAutomatic(0);
          dispatch(actionReload());
        }}
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
    </div>
  }

  if (loading || claimLoading || !claim) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  if (!claim) return null;

  return (
    <div
      className={cx(styles.root, {
        [styles.rootDesktop]: isNotMobile,
      })}
    >

      <div className={styles.chatContainer} id="chatContainer">
        {claimHistory.length === 0 &&
          <ChatAssessmentPending
            t={t}
            assessmentCreatedAt={assessmentCreatedAt}
          />
        }
        {claimStatusList &&
          <MessageList.Content key={`ml_${claim.id}_${claim.status}_${claim.orders ? claim.orders.length : 0}`}>
            {(isWaitingOriginal || isReportedOriginal) ? (
              <div className={styles.waitingOriginal}>
                <div className={styles.ico}>
                  <IcoInfo />
                </div>
                <div className={styles.header}>
                  {t('chatMessages.reqDocsOriginal')}
                </div>
                <ul>
                  {isWaitingOriginal && claim.requiredDocuments.filter(r => r.isOriginal__c).map(doc => (
                    <li key={`doc-${doc.Id}`}>
                      <strong>{getTitle(doc.Name)}</strong>
                    </li>
                  ))}
                  {isReportedOriginal && claim.reports[claim.reports?.length - 1]?.tags.filter(t => t.value.indexOf('_ORIGINAL') >= 0).map(tag => (
                    <li key={`doc-${tag.value}`}>
                      <strong>{getTitle(tag.value)}</strong>
                    </li>
                  ))}
                </ul>
                <ContactBranch title={t('chatMessages.reqDocsOriginalContactHeader')} hideDirectContact claim={claim} />
              </div>
            ) : (
              <>
                {claimStatusList}
              </>
            )}
          </MessageList.Content>
        }
        {claim.isBlocked &&
          <ClaimBlockedOnChat claim={claim} />
        }
        <div ref={bottomRef} />
      </div>
      {(caseResolutionModal && !claim?.isBlocked) &&
        <Modal
          open
          contentClassName={styles.modalContent}
          headerClassName={styles.modalHeader}
          onClose={() => setCaseResolutionModal(null)}
          title={t('resolutionModalTitles.repair')}
        >
          <RepairPickUpForm
            claimId={claim.id}
            close={() => setCaseResolutionModal(null)}
            error={""}
            initialValues={{
              name: (user?.firstName || '') + ' ' + (user?.lastName || ''),
              location: repairLocation as LocationT,
              date: getClosestWorkingDay(),
            }}
            loading={false}
            openSaveASDraftModal={() => null}
            isBlocked={!!claim?.isBlocked}
          />
        </Modal>
      }
    </div>
  );
}