import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReportUploadBox, { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { IMessage } from 'Components/ChatMessageGroup/ChatMessageGroup';
import runApi, { ResultApiTypeT, sendDataFiles } from 'api/base/runApi';
import { ClaimT, assessmentResult } from 'api/modules/claim';
import { ClaimHistoryT } from 'api/modules/case-resolution';
import style from './SparePartsPaxValidation.module.scss';
import { Button, ChatMessageGroup } from 'Components';
import { runNotify } from 'Helpers/notifications';
import Loading from 'Components/Loading/Loading';
import ImageDecrypt from "Components/ImageDecrypt/ImageDecrypt";

type PropsMainT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT,
}

const SparePartsPaxValidation: React.FC<PropsMainT> = ({ claim, statusInfo }) => {

    const [messages, setMessages] = useState<IMessage[]>([]);
    const [photo, setPhoto] = useState<IImage>(null);
    const [sending, setSending] = useState(false);
    const [send, setSend] = useState(false);
    const [assessmentResult, setAssessmentResult] = useState<assessmentResult>(claim?.assessmentResults && Array.isArray(claim.assessmentResults) && claim.assessmentResults.some(data => data.isActive) ? claim.assessmentResults.find(data => data.isActive) : null);
    const { t } = useTranslation();

    useEffect(() => {

        if (claim?.assessmentResults && Array.isArray(claim.assessmentResults) && claim.assessmentResults.some(data => data.isActive)){
            setAssessmentResult(claim.assessmentResults.find(data => data.isActive));
        } else {
            setAssessmentResult(null);
        }
    }, [claim?.assessmentResults]);

    const actionChangeImage = (photo: IImage) => {
        setPhoto(photo);
    }

    const removeDamageImage = (photo: IImage) => {
        setPhoto(null);
    }

    const actionSendWithPhoto = useCallback(() => {
        setSending(true);
        sendDataFiles(`claim/${claim.id}/assessment-result/spare-parts/upload-document`, [{ file: photo, name: 'spend_part' }], (r: ResultApiTypeT) => {
            setSending(false);
            if (!r.result){
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            } else {
                setSend(true);
            }
        });
    }, [claim.id, t, photo])

    const actionSendResignation = useCallback(() => {
        setSending(true);

        const dataSend = {

        };

        runApi(`claim/${claim.id}/assessment-result/spare-parts/send-refusal`, dataSend, (r: ResultApiTypeT) => {
            setSending(false);
            if (!r.result){
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            }
        });
    }, [claim.id, t])

    useEffect(() => {
        const _m: IMessage[] = [
            { message: t('chatMessages.sparePartsHeader') },
            { render: () => (
                    <FormInputData
                        actionSendResignation={actionSendResignation}
                        actionSendWithPhoto={actionSendWithPhoto}
                        actionChangeImage={actionChangeImage}
                        removeDamageImage={removeDamageImage}
                        sending={sending}
                        photo={photo}
                        t={t}
                        assessmentResult={assessmentResult}
                        send={send}
                    />
                )
            }
        ];

        setMessages(_m);
    }, [t, photo, sending, actionSendResignation, actionSendWithPhoto, assessmentResult, send]);

    return (
        <ChatMessageGroup
            data={messages}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        />
    );
}
export default SparePartsPaxValidation;

type FormInputDataT = {
    photo: IImage,
    removeDamageImage: (photo: IImage) => void,
    actionChangeImage: (photo: IImage) => void,
    t: (text: string, data?: any) => string,
    actionSendWithPhoto: () => void,
    actionSendResignation: () => void,
    sending: boolean,
    assessmentResult: assessmentResult,
    send: boolean
}

const FormInputData: React.FC<FormInputDataT> = ({ actionChangeImage, photo, removeDamageImage, t, actionSendWithPhoto, actionSendResignation, sending, assessmentResult, send }) => {

    return (
        <div className={style.root}>

            {!send &&
                <>
                    {(assessmentResult && assessmentResult.resolution.responseData) ?
                        <div className={style.dataExist}>
                            {(assessmentResult.resolution.responseData?.sparePartsPhotos && assessmentResult.resolution.responseData.sparePartsPhotos.length > 0) ?
                                <div className={style.photos}>
                                    <label>{t('chatMessages.sparePartsSendedPhoto')}</label>
                                    <div className={style.photosLayer}>
                                        {assessmentResult.resolution.responseData.sparePartsPhotos.map(photo => (
                                            <div className={style.photo} key={`ph-${photo.original.key}`}>
                                                <ImageDecrypt imageKey={photo.thumbnail.key} />
                                            </div>
                                        ))}
                                    </div>
                                </div>:
                                <div className={style.resign}>{t('chatMessages.sparePartsGiveup')}</div>
                            }
                        </div>:
                        <>
                            {sending ?
                                <div className={style.loading}>
                                    <Loading />
                                </div>:
                                <ReportUploadBox
                                    value={photo}
                                    removable={true}
                                    onRemove={() => removeDamageImage(photo)}
                                    onChange={(value) => actionChangeImage(value)}
                                    name={'photo-Input'}
                                    disabled={sending}
                                    hideName
                                    onlyImages
                                />
                            }
                            <div className={style.buttons}>
                                <Button color={'secondary'} onClick={actionSendResignation} disabled={sending}>{t('chatMessages.sparePartsButtonCancel')}</Button>
                                <Button disabled={!!!photo || sending} onClick={actionSendWithPhoto}>{t('chatMessages.sparePartsButtonConfirm')}</Button>
                            </div>
                        </>
                    }
                </>
            }

        </div>
    )
}