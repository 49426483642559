import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { codes } from 'country-calling-code';
import {  useSelector } from 'react-redux';
import cx from 'classnames';

import { SaveDataAsDraftModal } from 'PageComponents/CreateClaim';
import type { AppStateT } from 'Store/modules';
import style from './CreateForm.module.scss';
import Header from './Header/Header';
import Store from './Store';

import ReferenceNumberConfirmationModal from 'PageComponents/CreateClaim/ReferenceNumberConfirmationModal/ReferenceNumberConfirmationModal';
import ModalNotSupportedAirlinesAndContacts from 'Components/ModalNotSupportedAirlinesAndContacts/ModalNotSupportedAirlinesAndContacts';
import { checkValidByGoogle, getLocationByAddress, getAddressByLoc, RessultAddressByLocT, GoogleAddressT } from 'Helpers/googleApi';
import ModalNotLandingStationSupported from './ModalNotLandingStationSupported/ModalNotLandingStationSupported';
import { MissingBaseTypesE, StepsE, dependeciesReferenceNumber } from 'Store/Enums/NewClime';
import ModalNotAirlineSupported from './ModalNotAirlineSupported/ModalNotAirlineSupported';
import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import ModalConfirmAddress from './ModalConfirmAddress/ModalConfirmAddress';
import DocumentsOriginal from './Steps/DocumentsOriginal/DocumentsOriginal';
import MissingDocuments from './Steps/MissingDocuments/MissingDocuments';
import StepSplashScreen from './Steps/StepSplashScreen/StepSplashScreen';
import ModalDuplicatesRN from "./ModalDuplicatesRN/ModalDuplicatesRN";
import ReferenceNumber from './Steps/ReferenceNumber/ReferenceNumber';
import PhotosLuggage from './Steps/PhotosLuggage/PhotosLuggage';
import ModalTagNumbers from './ModalRagNumbers/ModalTagNumbers';
import { type StateT as UserStateT } from 'Store/modules/user';
import { getPropablyCountryNameByCode } from 'Helpers/strings';
import { saveDataNewClaimLocalStorage } from 'Helpers/store';
import PhotosDamage from './Steps/PhotosDamage/PhotosDamage';
import { CurrencyT } from 'Store/modules/luggageStore/shop';
import LuggageType from './Steps/LuggageType/LuggageType';
import MissedPhoto from './Steps/MissedPhoto/MissedPhoto';
import runApi, { ApiSendMethods } from 'api/base/runApi';
import { MissingDocExampleT } from "Store/modules/claim";
import InfoImages from "./Steps/InfoImages/InfoImages";
import { removePrefixFromPhone } from "Helpers/claim";
import { sortMissedDocument } from "services/claim";
import Material from './Steps/Material/Material';
import { setImageDataUrl } from "Helpers/files";
import Details from './Steps/Details/Details';
import Contact from './Steps/Contact/Contact';
import Summary from './Steps/Summary/Summary';
import Airline from './Steps/Airline/Airline';
import StepBegin from './Steps/Begin/Begin';
import Damage from './Steps/Damage/Damage';
import Colors from './Steps/Colors/Colors';
import Finish from './Steps/Finish/Finish';
import { Button } from 'Components';

type MainProps = {
    height: number,
    mobile: boolean,
    onClose?: () => void,
    actionSaveDraft: boolean,
    setCanShowClose?: (data: any) => void
}

export type RequiredDocumentT = {
    Id: string,
    Missing_Document_Type__c: string,
    Name: string,
    Obligatory__c: boolean,
    isOriginal__c: boolean,
    dataType__c: MissingBaseTypesE,
    Set__c?: string,
    Url__c?: string,
    examples?: string[],
}

export type MissingDocumentT = {
    document: IImage,
    fieldName?: string,
    requireDocument: RequiredDocumentT
}

export type LandingStationT = {
    currencyIsoCode: string,
    id: string,
    city: string,
    country: string,
    name: string
}

type ReferenceNumberStatuses = 'notChecked' | 'incorrect' | 'valid' | 'used' | 'invalid';

export type claimDetailsT = {
    brand: string,
    model: string,
    serialNumber: string,
    date: Date | null,
    cost: number,
    costKnow: boolean,
    length: number,
    height: number,
    capacity: number,
    width: number,
    functions: string,
    currency: string,
    size: string,
}

export type DamageT = {
    side: string,
    type: string,
}

const claimDetailsDefault: claimDetailsT = {
    brand: '',
    model: '',
    serialNumber: '',
    date: null,
    cost: 0,
    costKnow: true,
    length: 0,
    height: 0,
    capacity: 0,
    width: 0,
    functions: '',
    currency: 'EUR',
    size: '',
}

const defaultClaimCreate: ClaimCreateT = {
    referenceNumber: '',
    referenceNumberStatus: 'notChecked',
    referenceNumberValid: false,
    airlineName: '',
    airlineId: '',
    referenceNumberSource: null,
    airlineDateLanding: null,
    luggageType: '',
    luggageColor: '',
    luggageMaterial: '',
    details: claimDetailsDefault,
    damage: [],
    tagNumber: '',
    poNumber: '',
    photos: {
        front: undefined,
        back: undefined,
        top: undefined,
        bottom: undefined,
        left: undefined,
        right: undefined,
        missed_AirlineAuthorisation: undefined,
        missed_Tag: undefined,
        missed_BookingConfirmation: undefined,
        missed_Dpr: [],
        missed_RndCopy: undefined,
        missed_EmailFromAirline: undefined,
        missed_Eticket: undefined,
        missed_FlightTicket: undefined,
        missed_PurchaseReceipt: undefined,
        missed_StampedLeaflet: undefined,
        missed_Warranty: undefined,
        missed_AirlineProcessingNumber: undefined,
        missed_DamageReports: [],
        missed_Haqiba: undefined,
        missed_PermisionFromAirline: undefined,
        missed_BoardingCard: undefined,
        missed_PoNumber: undefined,
    },
    photoDamages: [],
    contact: {
        country: {
            code: '',
            name: ''
        },
        city: '',
        address: '',
        zipCode: '',
        phone: '',
        prefixPhone: null,
        travelling14days: null,
        coords: { lat: 0, lon: 0 }
    },
    missingDocuments: [],
    clientLocationCountry: {
        code: '',
        name: ''
    },
    clientLocationAirlineCountry: {
        code: '',
        name: ''
    },
    dateNextTravel: null,
    landingStationSelected: null
}

export interface ClaimCreateT {
    referenceNumber: string,
    referenceNumberStatus: ReferenceNumberStatuses,
    referenceNumberValid: boolean,
    airlineName: string,
    airlineId: string,
    referenceNumberSource: 'INTERNAL_AIRLINE_CODE' | 'WORLD_TRACER' | null,
    airlineDateLanding: Date | null,
    luggageType: string,
    luggageColor: string,
    luggageMaterial: string,
    details: claimDetailsT,
    damage: DamageT[],
    tagNumber: string,
    poNumber: string,
    photos: {
        [key: string]: IImage | IImage[] | undefined,
        front: IImage | undefined,
        back: IImage | undefined,
        top: IImage | undefined,
        bottom: IImage | undefined,
        left: IImage | undefined,
        right: IImage | undefined,
        missed_AirlineAuthorisation: IImage | undefined,
        missed_Tag: IImage | undefined,
        missed_BookingConfirmation: IImage | undefined,
        missed_Dpr: IImage[],
        missed_RndCopy: IImage | undefined,
        missed_EmailFromAirline: IImage | undefined,
        missed_Eticket: IImage | undefined,
        missed_FlightTicket: IImage | undefined,
        missed_PurchaseReceipt: IImage | undefined,
        missed_StampedLeaflet: IImage | undefined,
        missed_Warranty: IImage | undefined,
        missed_AirlineProcessingNumber: IImage | undefined,
        missed_DamageReports: IImage[],
        missed_Haqiba: IImage | undefined,
        missed_PermisionFromAirline: IImage | undefined,
        missed_PoNumber: IImage | undefined
    },
    photoDamages: IImage[],
    contact: {
        country: {
            code: string,
            name: string
        },
        city: string,
        address: string,
        zipCode: string,
        phone: string,
        prefixPhone: string | null,
        travelling14days: boolean | null,
        coords: { lat: number, lon: number }
    },
    missingDocuments: MissingDocumentT[],
    clientLocationCountry: {
        code: string,
        name: string
    },
    clientLocationAirlineCountry: {
        code: string,
        name: string
    },
    dateNextTravel: Date | null,
    landingStationSelected: LandingStationT | null
}

const defaultState: StateLocalT = {
    airlineSupported: false,
    airlineSupportedNoRefNum: false,
    landingStationSupported: false,
    airlineCode: '',
    landingStationCode: '',
    airlines: [],
    damageOptions: {
        SIDES: {},
        VALUES: {}
    },
    referenceNumber: '',
    widthSlider: 0,
    colors: {},
    baggageTypes: [],
    materials: [],
    valid: false,
    stepIndex: 0,
    step: null,
    goStepForward: 0,
    goStepBackword: 0,
    steps: [],
    goNext: 0,
    goPrev: 0,
    loading: false,
    loadingAirline: false,
    claimData: defaultClaimCreate,
    fk_caseInfo_bag_id: null,
    referenceNumberCounter: 0,
    brands: [],
    landingStations: [],
    editStepIndex: null,
    viewSlideSummaryType: null,
    termsConditions: false,
    termsConcentData: false,
    openDraft: false,
    requierdMissingDocuments: [],
    requierdMissingDocumentsCheck: [],
    missingDocumentsAdditionalInfo: [],
    showInfoNotAirlineSupport: false,
    showInfoNotAirlineSupportAndContact: false,
    redirectionToAirlineUrl:'',
    showInfoNotLandingStationSupport: false,
    finish: false,
    lastAddClaimId: '',
    lastAddClaimAutomatic: false,
    currencies: [],
    sending: false,
    branchContact: null,
    hideHeader: false,
    luggageTypes: {},
    tagNumbers: [],
    tagNumbersAccepted: [],
    showModalTagNumbers: false,
    branchCurrenciesByLocation: [],
    checkAddressByGoogle: 'noAction',
    wtBag: {
        purchaseDate: null,
        value: null,
        currencyIsoCode: null
    },
    imagesInClaim: [],
    casesInSalesforce: [],
    referenceNumberExamples: []
}

export type AirlineT = {
    Airline__c: string,
    Id: string,
    Name: string
}

export type DamageOptionsT = {
    // value: string,
    // text: string,
    SIDES: {
        [key: string]: string
    },
    VALUES: {
        [key: string]: string
    }
}

export type ColorsT = {
    [key: string]: any,
    slug: string,
    values: string[]
}

export type TagNumbersAcceptedT = {
    tagNumber: string,
    value: number | null,
    purchaseDate: string | null,
    currencyIsoCode: string | null
    id: string,
    brand: string,
    status: 'waiting' | 'processing' | 'done'
}

type FoundedBagFileT = {
    name: string,
    type: string,
    original: {
        key: string,
        url: string,
    }
}

export type FoundedBagT = {
    tagNumber: string,
    id: string,
    brand: string,
    color: string,
    files: {
        documents?: FoundedBagFileT[],
        photos?: FoundedBagFileT[]
    },
    readedResults: {
        purchaseDate: string | null,
        value: number | null,
        age: number,
        currencyIsoCode: string
    }
}

type DuplicateCaseT = {
    Account: {
        Email__c: string,
        PersonEmail: string
    },
    Airline__c: string,
    CaseNumber: string,
    CreatedDate: string,
    Id: string,
    LandingStation__c: string,
    OperationalStatus__c: string,
    Origin: string,
    ReferenceNo1__c: string | null,
    ReferenceNo2__c: string | null,
}

export type StateLocalT = {
    airlineSupported: boolean,
    airlineSupportedNoRefNum?: boolean,
    airlineCode: string,
    landingStationSupported: boolean,
    landingStationCode: string|null,
    airlines: AirlineT[],
    damageOptions: DamageOptionsT,
    colors: ColorsT | {},
    baggageTypes: string[],
    materials: string[],
    referenceNumber: string,
    widthSlider: number,
    step: StepT | null,
    stepIndex: number,
    goStepForward: number,
    goStepBackword: number,
    steps: StepT[],
    goNext: number,
    goPrev: number,
    loading: boolean,
    loadingAirline: boolean,
    valid: boolean,
    claimData: ClaimCreateT,
    fk_caseInfo_bag_id: string | null,
    referenceNumberCounter: number,
    brands: string[],
    landingStations: LandingStationT[],
    editStepIndex: number | null,
    viewSlideSummaryType: string | null,
    termsConditions: boolean,
    termsConcentData: boolean,
    openDraft: boolean,
    requierdMissingDocuments: RequiredDocumentT[],
    requierdMissingDocumentsCheck: RequiredDocumentT[],
    missingDocumentsAdditionalInfo: MissingDocExampleT[],
    showInfoNotAirlineSupport: boolean,
    showInfoNotAirlineSupportAndContact: boolean,
    redirectionToAirlineUrl?:string,
    showInfoNotLandingStationSupport: boolean,
    finish: boolean,
    lastAddClaimId: string,
    lastAddClaimAutomatic: boolean,
    currencies: CurrencyT[],
    sending: boolean,
    branchContact: {
        address: string,
        email: string,
        fullName: '',
        phoneNumber: string,
        url: string
    } | null,
    hideHeader: boolean,
    luggageTypes: { [key: string]: string },
    tagNumbers: FoundedBagT[],
    tagNumbersAccepted: TagNumbersAcceptedT[],
    showModalTagNumbers: boolean,
    branchCurrenciesByLocation: string[],
    checkAddressByGoogle: 'noAction' | 'checking' | 'resultError' | 'resultOk',
    wtBag: {
        purchaseDate: string | null,
        value: number | null,
        currencyIsoCode: string | null
    },
    imagesInClaim: IImage[],
    casesInSalesforce: DuplicateCaseT[],
    referenceNumberExamples: string[]
}

export type HeaderTypeT = 'icons' | 'avatar' | 'bigAvatar';
export type StepT = {
    type: any,
    buttonText: string,
    button2Text?: string,
    headerType: HeaderTypeT,
    headerText?: string,
    headerTitle?: string,
    headerNotRequired?: boolean,
    stepLabel?: string,
    valid: boolean,
    optional?: boolean,
    headerMiss?: boolean,
    missedDoc?: boolean
};

const MAX_TRY_REFERENCE_NUMBER = 3;
export const stepsMissingDocsScreens: any[] = [
    StepsE.BOARDING_CARD,
    StepsE.TAG_NUMBER,
    StepsE.EMAIL_FROM_AIRLINE,
    StepsE.FLIGHT_TICKET,
    StepsE.ETICKET,
    StepsE.PURCHASE_RECEIPT_COPY,
    StepsE.DAMAGE_RAPORT,
    StepsE.AIRLINES_AUTHORISATION_LETTER_COPY,
    StepsE.AIRLINE_PROCESSING_NUMBER,
    StepsE.BOOKING_CONFIRMATION,
    StepsE.DPR_COPY,
    StepsE.RND_COPY,
    StepsE.HAQIBA_COPY,
    StepsE.PERMISSION_FROM_AIRLINE,
    StepsE.WARRANTY_CARD_COPY,
    StepsE.PO_NUMBER,
];

export const PhotoKeys = {
    [StepsE.BOARDING_CARD]: 'missed_BoardingCard',
    [StepsE.TAG_NUMBER]: 'missed_Tag',
    [StepsE.ETICKET]: 'missed_Eticket',
    [StepsE.FLIGHT_TICKET]: 'missed_FlightTicket',
    [StepsE.EMAIL_FROM_AIRLINE]: 'missed_EmailFromAirline',
    [StepsE.PURCHASE_RECEIPT_COPY]: 'missed_PurchaseReceipt',
    [StepsE.DAMAGE_RAPORT]: 'missed_DamageReports',
    [StepsE.BOOKING_CONFIRMATION]: 'missed_BookingConfirmation',
    [StepsE.DPR_COPY]: 'missed_Dpr',
    [StepsE.RND_COPY]: 'missed_RndCopy',
    [StepsE.AIRLINES_AUTHORISATION_LETTER_COPY]: 'missed_AirlineAuthorisation',
    [StepsE.STAMPED_LEAFLET]: 'missed_StampedLeaflet',
    [StepsE.WARRANTY_CARD_COPY]: 'missed_Warranty',
    [StepsE.AIRLINE_PROCESSING_NUMBER]: 'missed_AirlineProcessingNumber',
    [StepsE.HAQIBA_COPY]: 'missed_Haqiba',
    [StepsE.PERMISSION_FROM_AIRLINE]: 'missed_PermisionFromAirline',
    [StepsE.PO_NUMBER]: 'missed_PoNumber',
}

export const defaultCountry = 'de';
const enableConfirmWrongReferenceNumber = false;

const CreateForm: React.FC<MainProps> = ({ height, mobile, onClose, actionSaveDraft, setCanShowClose }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useReducer(Store, {
        ...defaultState,
        claimData: {
            ...defaultState.claimData,
            contact: {
                ...defaultState.claimData.contact,
                country: {
                    name: user?.location?.country || getPropablyCountryNameByCode(user?.location?.countryCode || defaultCountry),
                    code: user?.location?.countryCode || defaultCountry
                },
                city: user?.location?.city || '',
                zipCode: user?.location?.zipCode || '',
                address: user?.location?.address || '',
                phone: removePrefixFromPhone(user?.phoneNumber || '', codes.find(c => c.isoCode2 === user?.location?.countryCode)?.countryCodes[0] || null),
                prefixPhone: codes.find(c => c.isoCode2 === user?.location?.countryCode)?.countryCodes[0] || null
            },
    
            // Emergency, first country assignment
            clientLocationCountry: {
                name: user?.location?.country || getPropablyCountryNameByCode(user?.location?.countryCode || defaultCountry),
                code: user?.location?.countryCode || defaultCountry
            },
            clientLocationAirlineCountry: {
                name: user?.location?.country || getPropablyCountryNameByCode(user?.location?.countryCode || defaultCountry),
                code: user?.location?.countryCode || defaultCountry
            },
        },
        showModalTagNumbers: false
    });
    
    const [numberConfirmIsOpen, setNumberConfirmIsOpen] = useState<boolean>(false);
    const [possibleAddresses, setPossibleAddresses] = useState<RessultAddressByLocT[]>([]);

    /*
     * Setting summary images includes in claim
     **/
    useEffect(() => {
        const _array = [];
        const data = Object.entries(state.claimData.photos).filter(ph => !!ph[1]).map(ph => ph[1]);
        for (let i = 0; i < data.length; i++) {
            if (!Array.isArray(data[i])) {
                _array.push(data[i]);
            } else if (Array.isArray(data[i])) {
                // console.log('isArray: ', data[i]);
                //@ts-ignore
                data[i].filter(ph => !!ph).forEach(ph => {
                    if (!!ph) {
                        _array.push(ph);
                    }
                })
            }
        }
        setState({
            type: 'SET_IMAGES_IN_CLAIM',
            data: [
                ...state.claimData.photoDamages,
                ..._array
            ]
        });
    }, [state.claimData.photos, state.claimData.photoDamages]);

    useEffect(() => {
        if (setCanShowClose) {
            let canShow = !state.finish;
            if (!state.finish && !(state.claimData.referenceNumber || state.claimData.airlineName)) {
                canShow = false;
            }
            setCanShowClose(canShow);
        }
    }, [state.finish, setCanShowClose, state.claimData.referenceNumber, state.claimData.airlineName]);

    useEffect(() => {
        if (actionSaveDraft) {
            // saving data draft
            const runSaveData = async () => {
                await saveDataNewClaimLocalStorage(state.claimData, state.termsConcentData, state.termsConditions);
                onClose?.();
            };
            runSaveData();
        }
    }, [actionSaveDraft, onClose, state.claimData, state.termsConcentData, state.termsConditions]);

    useLayoutEffect(() => {
        setTimeout(() => {
            const data = document.getElementById('slideBody');
            if (data) {
                setState({ type: 'SET_WIDTH_SLIDER', width: data.getBoundingClientRect().width });
            }
        }, 0);
        checkDataApi();
        navigator.geolocation.getCurrentPosition(function (position) {
            setState({ type: 'SET_GEOLOCALIZATION', lat: position.coords.latitude, lon: position.coords.longitude });
        });
    }, []);

    const updateSteps = useCallback((data: { type: string, field: string, value: any }[]) => {
        setState({
            type: 'UPDATE_STEPS', data:
                state.steps.map(step => {
                    const foundStep = data.find(st => st.type === step.type);
                    if (foundStep) {
                        step[foundStep.field] = foundStep.value;
                    }
                    return step;
                })
        });
    }, [state.steps])

    const updateClaim = useCallback((dataType: string, data: any) => {
        setState({ type: 'UPDATE_CLAIM_DATA', dataType, data });
    }, []);

    const updateState = useCallback((dataType: string, data: any) => {
        setState({ type: 'UPDATE_STATE', dataType, data });
    }, []);

    const updateLocation = useCallback((lat: number, lon: number) => {
        setState({ type: 'SET_GEOLOCALIZATION', lat, lon });
    }, [])

    const setValidStep = useCallback((result: boolean) => {
        setState({ type: 'SET_STEP_VALID', data: result });
    }, []);

    const addressObject: GoogleAddressT = useMemo(() => {
        return {
            address: state.claimData.contact.address,
            zipCode: state.claimData.contact.zipCode,
            countryName: state.claimData.contact.country.name,
            countryCode: state.claimData.contact.country.code,
            city: state.claimData.contact.city,
        };
    }, [state.claimData.contact.address, state.claimData.contact.zipCode, state.claimData.contact.country.name, state.claimData.contact.country.code, state.claimData.contact.city]);

    const defaultSteps: StepT[] = useMemo(() => [
        // { type: 'splash', buttonText: t('createClaim.buttonBegin'), headerType: 'bigAvatar', valid: true },
        { type: StepsE.BEGIN, buttonText: t('createClaim.buttonStart'), headerType: 'avatar', valid: true },
        { type: StepsE.WARNING_ATTACHMENTS, buttonText: t('createClaim.buttonStart'), headerType: 'avatar', valid: true },
        { type: StepsE.REFERENCE_NUMBER, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerFlight'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false },
        { type: StepsE.AIRLINE, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerWhichAirline'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true },
        { type: StepsE.MISSING_DOCUMENTS, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerSendReqDocs'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.MISSING_DOCUMENTS_ORIGINAL, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.origianDocumentsHeader'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: true, optional: true, missedDoc: true },

        { type: StepsE.TAG_NUMBER, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderTag'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.ETICKET, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderEticket'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.DPR_COPY, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderDamageReport'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.RND_COPY, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missedRndCopy'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.EMAIL_FROM_AIRLINE, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderEmailAirline'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.FLIGHT_TICKET, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderTicket'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.PURCHASE_RECEIPT_COPY, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderTicketReceipt'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.DAMAGE_RAPORT, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderDamageReport'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.AIRLINES_AUTHORISATION_LETTER_COPY, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderAuthLetter'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.AIRLINE_PROCESSING_NUMBER, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderAirlineProccessingNumber'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.BOOKING_CONFIRMATION, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderBookingConfirmation'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.HAQIBA_COPY, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderHaquiba'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.PERMISSION_FROM_AIRLINE, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderPermissionFromAirline'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.STAMPED_LEAFLET, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderStampedLeaflet'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.WARRANTY_CARD_COPY, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderWarrantyCard'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.BOARDING_CARD, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missHeaderBoardingCard'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },
        { type: StepsE.PO_NUMBER, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.missPoNumberPlaceholder'), stepLabel: t('createClaim.headerStep', { step: '1/5' }), valid: false, optional: true, missedDoc: true },

        { type: StepsE.WAIT_0, buttonText: t('createClaim.buttonStep2'), headerType: 'bigAvatar', headerText: t('createClaim.headerGeneral'), valid: true, headerTitle: t('createClaim.headerGreat') },
        { type: StepsE.TYPE_OF_LUGGAGE, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerDamaged'), stepLabel: t('createClaim.headerStep', { step: '2/5' }), valid: false },
        { type: StepsE.COLOR, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerColor'), stepLabel: t('createClaim.headerStep', { step: '2/5' }), valid: false },
        { type: StepsE.MATERIAL, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerMaterial'), headerNotRequired: true, stepLabel: t('createClaim.headerStep', { step: '2/5' }), valid: true },
        { type: StepsE.WAIT_1, buttonText: t('createClaim.buttonStep3'), headerType: 'bigAvatar', headerText: t('createClaim.headerDetails'), valid: true, headerTitle: t('createClaim.headerThank') },
        { type: StepsE.DETAILS, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerDetailsNow'), stepLabel: t('createClaim.headerStep', { step: '3/5' }), valid: true },
        { type: StepsE.DAMAGE, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: t('createClaim.headerDescribeDamage'), stepLabel: t('createClaim.headerStep', { step: '3/5' }), valid: false },
        { type: StepsE.WAIT_PHOTOS, buttonText: t('createClaim.buttonStepLast'), headerTitle: t('createClaim.headerOkWeGot'), headerType: 'bigAvatar', headerText: t('createClaim.headerNextStep'), valid: true },
        { type: StepsE.DAMAGES, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: '', stepLabel: t('createClaim.headerStep', { step: '4/5' }), valid: true },
        { type: StepsE.LUGGAGE, buttonText: t('createClaim.buttonNext'), headerType: 'avatar', headerText: '', stepLabel: t('createClaim.headerStep', { step: '4/5' }), valid: true },
        { type: StepsE.WAIT_2, buttonText: t('createClaim.buttonStepLast'), headerType: 'bigAvatar', headerText: t('createClaim.headerInLast'), valid: true, headerTitle: t('createClaim.headerLastStep') },
        { type: StepsE.CONTACT, buttonText: t('createClaim.buttonConfirm'), headerType: 'icons', headerText: t('createClaim.headerAddAddress'), stepLabel: t('createClaim.headerStep', { step: '5/5' }), valid: false },
        { type: StepsE.WAIT_3, buttonText: t('createClaim.buttonSummary'), headerType: 'bigAvatar', headerText: t('createClaim.headerAllNecessary'), valid: true },
        { type: StepsE.SUMMARY, buttonText: t('createClaim.buttonConfirm'), headerType: 'icons', headerText: '', valid: false },
        { type: StepsE.FINISH, buttonText: t('createClaim.buttonBackMain'), headerType: 'bigAvatar', headerText: '', valid: true },
    ], [t]);

    useEffect(() => {
        const steps: StepT[] = JSON.parse(JSON.stringify(defaultSteps));
        setState({ type: 'SET_STEPS', data: steps });
    }, [t, defaultSteps]);

    const disableMissingDocuments = (activeAirline = false, update = true) => {
        const toRemoved = state.steps.filter(step => stepsMissingDocsScreens.includes(step.type)).map(step => {
            return { type: step.type, field: 'optional', value: true }
        });
        toRemoved.push({ type: StepsE.MISSING_DOCUMENTS as any, field: 'optional', value: true });
        toRemoved.push({ type: StepsE.MISSING_DOCUMENTS_ORIGINAL as any, field: 'optional', value: true });
        toRemoved.push({ type: StepsE.AIRLINE as any, field: 'optional', value: !activeAirline });
        if (update && toRemoved.length > 0) {
            updateSteps(toRemoved);
        }
        return toRemoved;
    }

    const validReferenceNumberAction = (status = 'valid', missingDocuments: RequiredDocumentT[] = []) => {

        updateClaim('referenceNumberStatus', status);
        setValidStep(status === 'valid');
        const requiredDocuments: RequiredDocumentT[] = missingDocuments.filter(req => req.dataType__c === MissingBaseTypesE.FILE && req.Url__c);
        const reqDocs = [];
        if (requiredDocuments.length > 0 || (requiredDocuments.length === 0 && missingDocuments.length > 0)) {
            // if (missingDocuments.some(doc => doc.isOriginal__c && (!doc?.Set__c || (doc?.Set__c && !requiredDocuments.some(d => d.Set__c && d.Set__c === doc.Set__c))))){
            if (missingDocuments.some(doc => doc.isOriginal__c)) {
                let foundNoSet = false;
                missingDocuments.filter(doc => doc.isOriginal__c).forEach(doc => {
                    if (!doc.Set__c || (!!doc.Set__c && !missingDocuments.some(d => !!d.Set__c && d.Set__c === doc.Set__c && !d.isOriginal__c))) {
                        foundNoSet = true;
                    }
                });
                if (foundNoSet) {
                    reqDocs.push({ type: StepsE.MISSING_DOCUMENTS_ORIGINAL, field: 'optional', value: false });
                }
            }
        }
        if (missingDocuments.some(doc => doc.Obligatory__c && doc.Url__c && !doc.isOriginal__c)) {
            reqDocs.push({ type: StepsE.MISSING_DOCUMENTS, field: 'optional', value: false });
        }
        reqDocs.push({ type: StepsE.AIRLINE, field: 'optional', value: true });
        if (reqDocs.length > 0) {
            updateSteps(reqDocs);
        }
    }

    const checkDataApi = () => {

        runApi('claim/register/data', {}, (result: any) => {
            if (result.result) {
                let brands = [];
                let airlines = [];
                let damageOptions = {};
                let colors = [];
                let baggageTypes = [];
                let materials = [];
                let currencies = [];
                let landingStations = [];
                let luggageTypes = {};
                if (result.data?.brands) brands = result.data.brands;
                if (result.data?.airlines) airlines = result.data.airlines;
                if (result.data?.damageOptions) damageOptions = result.data.damageOptions;
                if (result.data?.colors) colors = result.data.colors;
                if (result.data?.baggageTypes) baggageTypes = result.data.baggageTypes;
                if (result.data?.materials) materials = result.data.materials;
                if (result.data?.currencies) currencies = Object.entries(result.data.currencies).map(el => el[1]);
                if (result.data?.luggageTypes) luggageTypes = result.data.luggageTypes;
                const referenceNumberExamples = result.data?.referenceNumberExamples && Array.isArray(result.data.referenceNumberExamples) ? result.data.referenceNumberExamples : [];
                
                if (result.data?.landingStation && Array.isArray(result.data.landingStation)) landingStations = result.data.landingStation.map((ls: any) => {
                    return {
                        currencyIsoCode: ls.CurrencyIsoCode,
                        id: ls.Id,
                        city: ls.LandingCity__c,
                        country: ls.LandingCountry__c?.replace(/ +/g, '_'),
                        name: ls.Name
                    };
                });

                setState({ type: 'SET_BASIC_DATA', brands, airlines, damageOptions, colors, baggageTypes, materials, currencies, landingStations, luggageTypes, referenceNumberExamples });
            }
        }, ApiSendMethods.get);
    }

    const actionNewClaim = useCallback(() => {

        if (state.tagNumbersAccepted.some(tag => tag.status === 'processing')) {
            // clear all data except contact
            return setState({ type: 'CLEAR_DATA_NEXT_TAG' });
        }

        setState({
            type: 'SET_NEW_STATE',
            defaultState: defaultState,
            steps: JSON.parse(JSON.stringify(defaultSteps))
        });
        // setState({ type: 'SET_STATE', data: {// xxx
        //     ...defaultState,
        //     steps: JSON.parse(JSON.stringify(defaultSteps)),
        //     stepIndex: 0,
        //     step: defaultSteps[0],
        //     brands: state.brands,
        //     damageOptions: state.damageOptions,
        //     finish: false,
        //     baggageTypes: state.baggageTypes,
        //     materials: state.materials,
        //     airlines: state.airlines,
        //     colors: state.colors,
        //     referenceNumberCounter: 0,
        //     lastAddClaimId: '',
        //     currencies: state.currencies,
        //     hideHeader: false,
        //     landingStationSelected: null,
        //     landingStations: state.landingStations,
        //     luggageTypes: state.luggageTypes,
        //     claimData: {
        //         ...defaultState.claimData,
        //         contact: {
        //             ...state.claimData.contact
        //         }
        //     }
        // } });
    }, [defaultSteps, state.tagNumbersAccepted]);

    const sendLandingData = () => {

        const dataSend = {
            referenceNumber: null,
            clientLocationCountry: state.claimData.clientLocationAirlineCountry,
            airline: {
                id: state.claimData.airlineId,
                name: state.airlines.find(a => a.Id === state.claimData.airlineId).Name
            },
            landingStation: {
                id: state.claimData.landingStationSelected.id,
                name: state.claimData.landingStationSelected.name,
            },
            landingDate: state.claimData.airlineDateLanding
        }

        setState({ type: 'SET_AIRLINE_LOADING', data: true });
        runApi('claim/register/check', dataSend, (r: any) => {
            checkResultApi(r, false);
        });

    }

    // wyszukuje i ustawia aktywuje wszytskie widoki missing documents
    const setupMisingDocumentsViews = (missedDocuments: RequiredDocumentT[]) => {
        setState({
            type: 'UPDATE_STEPS', data: [
                ...state.steps.map(step => {
                    if (stepsMissingDocsScreens.includes(step.type) && missedDocuments.some(doc => doc.Missing_Document_Type__c === step.type)) {
                        step.optional = false;
                    }
                    return step;
                })
            ]
        });
    }

    const setStepHidden = useCallback((stepParam: StepsE, hidden = true) => {
        setState({
            type: 'UPDATE_STEPS', data: [
                ...state.steps.map(step => {
                    if (stepParam === step.type) {
                        step.optional = hidden;
                    }
                    return step;
                })
            ]
        });
    }, [state.steps]);

    const removeRequireDocumentsDuplicates = useMemo(() => (docs: RequiredDocumentT[]): RequiredDocumentT[] => {
        return docs.filter((doc, i) => i > 0 ? docs[i - 1].Missing_Document_Type__c !== doc.Missing_Document_Type__c : true);
    }, []);

    const removeRequireDocumentsDependencesFileReference = useMemo(() => (docs: RequiredDocumentT[], searchDependecies: boolean): RequiredDocumentT[] => {

        const fileReference = docs.find(doc => (doc.Missing_Document_Type__c === StepsE.REFERENCE_NUMBER || doc.Missing_Document_Type__c === 'File reference') && doc.Set__c && doc.Set__c !== '0');
        if (!fileReference) {
            return docs;
        }

        return searchDependecies && fileReference?.Set__c && fileReference.Set__c !== '0' ? docs.filter((doc) => doc.Set__c === fileReference.Set__c ? !dependeciesReferenceNumber.includes(doc.Missing_Document_Type__c as StepsE) : true) : docs;
    }, []);

    const actionIncorrectReferenceNumber = () => {
        if (enableConfirmWrongReferenceNumber) {
            return setNumberConfirmIsOpen(true);
        }
        // xxx, go to next step
        confirmReferenceNumber();
    }

    const checkResultApi = (r: any, checkReferenceNumber = true) => {
        let duplicatesReferenceNumber: DuplicateCaseT[] = [];
        let documentsOrigin = [];
        if (r.result) {
            const missingDocuments = [];

            /*
             * Zerowanie widoków
             * - wyłączenie missing documents
             * - pokazanie widoków zdjęć gdyby zostały wcześniej ukryte
             **/
            const stepsMissingDocuments = disableMissingDocuments(false, false);
            updateSteps([
                ...stepsMissingDocuments,
                ...[
                    { type: StepsE.DAMAGES as any, field: 'optional', value: false },
                    { type: StepsE.LUGGAGE as any, field: 'optional', value: false },
                    { type: StepsE.WAIT_2 as any, field: 'optional', value: false },
                    { type: StepsE.WAIT_PHOTOS as any, field: 'optional', value: false },
                ]
            ]);

            const additionals = [];

            documentsOrigin = r.data?.requierdMissingDocuments || [];
            const documents = [
                ...(r.data.requierdMissingDocuments && Array.isArray(r.data.requierdMissingDocuments) && r.data.requierdMissingDocuments.length > 0 ?
                    removeRequireDocumentsDuplicates(
                        removeRequireDocumentsDependencesFileReference(
                            r.data.requierdMissingDocuments.sort(sortMissedDocument),
                            !!r.data.status
                        )
                    ) : []
                ),
                ...additionals
            ];

            if (!r.data.claim || (r.data.claim && (typeof r.data.claim).toLowerCase() === 'string')) {
                validReferenceNumberAction('valid', documents);
            } else {
                /*
                 * Claim exist
                 **/

            }
            if (documents.length > 0) {
                setupMisingDocumentsViews(documents);
            }

            const isDuplicates = !!r.data?.casesInSalesforce && Array.isArray(r.data.casesInSalesforce) && r.data.casesInSalesforce.length > 0;
            if (isDuplicates) {
                duplicatesReferenceNumber = [...r.data.casesInSalesforce];
            }

            if (r.data.status) {
                if (checkReferenceNumber) {
                    updateSteps([{ type: StepsE.AIRLINE as string, field: 'optional', value: true }]);
                    updateClaim('airlineId', null);
                }
                if (!isDuplicates) {
                    setState({ type: 'GO_NEXT' });
                }
            } else {

                if (checkReferenceNumber) setState({ type: 'INCREASE_CHECKREFERENCE_NUMBER' });
                else {
                    updateSteps([{ type: StepsE.AIRLINE as string, field: 'optional', value: false }]);
                }
                if (checkReferenceNumber && state.referenceNumberCounter + 1 >= MAX_TRY_REFERENCE_NUMBER) {

                    disableMissingDocuments(true);
                    if (!isDuplicates) {
                        setState({ type: 'GO_NEXT' });
                    }
                } else if (checkReferenceNumber) {
                    actionIncorrectReferenceNumber();
                } else {
                    // check airline
                    let index = findNextStep(state.steps, state.stepIndex);
                    if (missingDocuments.length > 0) {
                        index = state.steps.findIndex((el: StepT) => el.type === StepsE.MISSING_DOCUMENTS);
                    }

                    setState({
                        type: 'SET_STEP',
                        data: index || state.stepIndex
                    });
                }
            }
     
            const requierdMissingDocuments = documents && Array.isArray(documents) ? documents.filter(doc => doc.Obligatory__c) : [];
            setState({
                type: 'SET_CHECK_DATA',
                airlineSupported: !r.data.status || (r.data.status && r.data.airlineSupported),
                airlineSupportedNoRefNum: r.data?.airlineSupported,
                redirectionToAirlineUrl: r.data?.redirectionToAirline?.url || '',
                landingStationSupported: !r.data.status || (r.data.status && r.data.landingStationSupported),
                requierdMissingDocuments,
                branchCurrenciesByLocation: r.data.branchCurrenciesByLocation || [],
                branchContact: r.data.branchContact || null,
                referenceNumberStatus: !checkReferenceNumber ? 'notChecked' : null,
                foundedBags: r.data?.foundedBags,
                airlineCode: r.data?.airlineCode,
                landingStationCode: r.data?.landingStationCode,
                referenceNumberSource: r.data?.referenceNumberSource || null,
                status: r.data.status,
                checkValidReferenceNumber: checkReferenceNumber,
                duplicatesReferenceNumber,
                documentsOrigin,
                missingDocumentsAdditionalInfo: r.data?.missingDocumentsAdditionalInfo && Array.isArray(r.data?.missingDocumentsAdditionalInfo) ? r.data.missingDocumentsAdditionalInfo : []
            });
            /*
             * Jeśli będzie jeden
             **/
            if (r.data?.foundedBags && Array.isArray(r.data.foundedBags) && r.data.foundedBags.length === 1) {
                importPhotoBag(r.data.foundedBags[0]);
            }
        } else {
            const additional: RequiredDocumentT[] = [
            ];

            setState({
                type: 'SET_CHECK_DATA',
                airlineSupported: true,
                airlineSupportedNoRefNum: r.data?.airlineSupported,
                redirectionToAirline: r.data?.redirectionToAirline?.url || [],
                landingStationSupported: true,
                airlineCode: r.data?.airlineCode,
                landingStationCode: r.data?.landingStationCode,
                requierdMissingDocuments: [...additional],
                documentsOrigin
            });

            if (checkReferenceNumber) {
                actionIncorrectReferenceNumber();
            } else {
                console.log('errr reference ');
                // czy iść dalej ????
                setState({ type: 'GO_NEXT' });
            }
        }
    }

    const checkReferenceNumber = (data: string) => {
        setState({ type: 'SET_LOADING', data: true });
        const dataSend = {
            referenceNumber: data,
            clientLocationCountry: state.claimData.clientLocationCountry,
            landingDate: null
        }
        runApi('claim/register/check', dataSend, (r: any) => {
            checkResultApi(r);
        });
    }

    const findNextStep = (steps: StepT[], stepIndex: number): number => {
        return steps.findIndex((el: StepT, _index: number) => _index > stepIndex && !el.optional) || stepIndex;
    }

    const goNextAction = (forward = true) => {

        if (state.editStepIndex !== null) {
            const stepSummaryIndex = state.steps.findIndex(st => st.type === 'summary');
            if (stepSummaryIndex >= 0) {
                return setState({ type: 'SET_STEP', data: stepSummaryIndex });
            }
        }

        if (forward) {

            if (state.step?.type === 'contact') {

                actionCheckValidByGoogle()
                    .then(result => {
                        if (result) {
                            setState({ type: 'GO_NEXT' });
                        }
                    })
                    .catch(err => {
                        setState({ type: 'GO_NEXT' });
                    });

                return;
            }

            if (state.step?.type === 'reference' && (state.claimData.referenceNumberStatus === 'notChecked' || state.claimData.referenceNumberStatus === 'invalid')) {
                return checkReferenceNumber(state.claimData.referenceNumber);
            } else if (state.step?.type === 'airline') {
                return sendLandingData();
            }
            setState({ type: 'GO_NEXT' });

        } else {
            setState({ type: 'GO_PREV' });
        }

    }

    const closeAction = () => {
        onClose?.();
    }

    // wyłączenie widoku ze zdjęciami
    const clearPhotoView = useCallback((viewType: string) => {
        let type = '';
        if (viewType === StepsE.LUGGAGE) {
            type = StepsE.LUGGAGE
        } else if (viewType === StepsE.DAMAGES) {
            type = StepsE.DAMAGES;
        }
        if (type) {
            const step = state.steps.find(s => s.type === type && !s.optional);
            if (step) {
                updateSteps([
                    { field: 'optional', type, value: true },
                    { field: 'optional', type: StepsE.WAIT_2, value: true },
                    { field: 'optional', type: StepsE.WAIT_PHOTOS, value: true },
                ]);
            }
        }
    }, [updateSteps, state.steps]);

    const importPhotoBag = useCallback(async (bag: FoundedBagT) => {
        const getLabel = (data: string) => {
            const possibles = ['front', 'back', 'top', 'bottom', 'left', 'right'];
            return possibles.find(d => data.toLowerCase().indexOf(d) >= 0);
        }

        // if (bag && bag.files && (bag.files.documents || bag.files.photos)) {
        if (bag && bag.files && bag.files.photos) {
            const photosData: FoundedBagFileT[] = [...(bag.files?.photos || [])];
            let dataFile = null;
            let result = { photoDamages: [], photos: [] };
            for (const data in photosData) {
                dataFile = photosData[data];
                if (dataFile.type === 'damageArea') {
                    const dataResultDamages = await setImageDataUrl(dataFile.original.url);
                    if (dataResultDamages) {
                        result = { ...result, photoDamages: [...result.photoDamages, ...[dataResultDamages]] }
                    }
                } else {
                    const label = getLabel(dataFile.name);
                    if (label && !result.photos[label]) {
                        const dataResultPhotos = await setImageDataUrl(dataFile.original.url);
                        if (dataResultPhotos) {
                            result = { ...result, photos: { ...result.photos, ...{ [label]: { ...dataResultPhotos, file: { ...dataResultPhotos.file, type: 'image' } } } } }
                        }
                    }
                }
            };
            if (result.photoDamages.length > 0 || result.photos.length > 0) {
                setState({ type: 'ADD_WT_ATTACHEMENTS', photoDamages: result.photoDamages, photos: result.photos });
                if (result.photoDamages.length > 0) {
                    clearPhotoView(StepsE.DAMAGES);
                }
                if (result.photos.length > 0) {
                    clearPhotoView(StepsE.LUGGAGE);
                }
            }
        }
    }, [clearPhotoView]);

    const setEditSlide = useCallback((slugIndex: string, detail?: string) => {

        const stepIndex = state.steps.findIndex(st => st.type === slugIndex);
        if (stepIndex >= 0) {
            setState({ type: 'SET_STEP_EDIT', data: stepIndex, editStepIndex: stepIndex });
        }

    }, [state.steps]);

    const discardDraft = () => {
        setState({ type: 'SET_OPEN_DRAFT', data: false });
        onClose?.();
        // remove draft data
    }

    const saveDraft = () => {

    }

    const actionFinished = useCallback((data = true) => {
        setState({ type: 'SET_FINISH', data });
    }, []);

    const actionSetEditIndex = useCallback((data: number | null) => {
        setState({ type: 'SET_ONLY_STEP_EDIT', data });
    }, []);

    const setCountryName = useCallback((data: string) => {
        setState({ type: 'SET_COUNTRY_NAME', data });
    }, []);

    const actionHideAvatar = useCallback((data: boolean) => {
        setState({ type: 'SET_HIDE_HEADER', data });
    }, []);

    // ustawienie widoków podczas kolejnego kroku // xxx
    const actionNextTags = useCallback((data: any, checkFoundBags = false) => {
        setState({ type: 'SET_TAG_NUMBERS_ACCEPTED', data });

        const toRemoved = [];

        // wyłączanie widoków
        const removeViews = [
            StepsE.MISSING_DOCUMENTS,
            StepsE.MISSING_DOCUMENTS_ORIGINAL,
            StepsE.AIRLINE,
            StepsE.BEGIN,
            StepsE.WAIT_0,
            StepsE.REFERENCE_NUMBER,
            StepsE.AIRLINE,
            // StepsE.DAMAGES,
            // StepsE.LUGGAGE,
            StepsE.CONTACT,
            // StepsE.WAIT_PHOTOS,
            StepsE.WAIT_2,
            ...stepsMissingDocsScreens
        ];
        removeViews.forEach(v => {
            toRemoved.push({ type: v as any, field: 'optional', value: true });
        });
        updateSteps(toRemoved);

        if (checkFoundBags) {
            const foundActualBag = data.find(tag => tag.status === 'processing');
            if (foundActualBag && state.tagNumbers.some(bg => bg.id === foundActualBag.id)) {
                const bag = state.tagNumbers.find(bg => bg.id === foundActualBag.id);
                if (bag) {
                    importPhotoBag(bag);
                }
            }
        }

        // setState({ type: 'SET_STEP', data: state.steps.findIndex(step => step.type === StepsE.TYPE_OF_LUGGAGE ) });
    }, [importPhotoBag, state.tagNumbers, updateSteps]);

    const chooseContent = useMemo(() => (step: StepT) => {
        switch (step.type) {
            case 'splash': return <StepSplashScreen user={user} />;
            case StepsE.BEGIN: return <StepBegin user={user} setStepHidden={setStepHidden} />;
            case StepsE.REFERENCE_NUMBER: return <ReferenceNumber actionSetEditIndex={actionSetEditIndex} state={state} setValidStep={setValidStep} updateClaim={updateClaim} />;
            case StepsE.WARNING_ATTACHMENTS: return <InfoImages setStepHidden={setStepHidden} />;
            case StepsE.AIRLINE: return <Airline actionSetEditIndex={actionSetEditIndex} state={state} validStep={setValidStep} updateState={updateState} updateClaim={updateClaim} mobile={mobile} />;
            case StepsE.TYPE_OF_LUGGAGE: return <LuggageType actionSetEditIndex={actionSetEditIndex} state={state} setValidStep={setValidStep} updateClaim={updateClaim} />;
            case StepsE.COLOR: return <Colors state={state} setValidStep={setValidStep} updateClaim={updateClaim} mobile={mobile} />;
            case StepsE.MATERIAL: return <Material updateClaim={updateClaim} luggageMaterial={state.claimData.luggageMaterial} materials={state.materials} />;
            case StepsE.DETAILS: return <Details state={state} setValidStep={setValidStep} updateClaim={updateClaim} />;
            case StepsE.DAMAGE: return <Damage state={state} setValidStep={setValidStep} updateClaim={updateClaim} mobile={mobile} />;

            case StepsE.BOARDING_CARD: return <MissedPhoto missingType={StepsE.BOARDING_CARD} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.TAG_NUMBER: return <MissedPhoto missingType={StepsE.TAG_NUMBER} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.ETICKET: return <MissedPhoto missingType={StepsE.ETICKET} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.FLIGHT_TICKET: return <MissedPhoto missingType={StepsE.FLIGHT_TICKET} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.EMAIL_FROM_AIRLINE: return <MissedPhoto missingType={StepsE.EMAIL_FROM_AIRLINE} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.PURCHASE_RECEIPT_COPY: return <MissedPhoto missingType={StepsE.PURCHASE_RECEIPT_COPY} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.DAMAGE_RAPORT: return <MissedPhoto missingType={StepsE.DAMAGE_RAPORT} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.BOOKING_CONFIRMATION: return <MissedPhoto missingType={StepsE.BOOKING_CONFIRMATION} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.DPR_COPY: return <MissedPhoto missingType={StepsE.DPR_COPY} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.RND_COPY: return <MissedPhoto missingType={StepsE.RND_COPY} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.AIRLINES_AUTHORISATION_LETTER_COPY: return <MissedPhoto missingType={StepsE.AIRLINES_AUTHORISATION_LETTER_COPY} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.STAMPED_LEAFLET: return <MissedPhoto missingType={StepsE.STAMPED_LEAFLET} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.WARRANTY_CARD_COPY: return <MissedPhoto missingType={StepsE.WARRANTY_CARD_COPY} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.AIRLINE_PROCESSING_NUMBER: return <MissedPhoto missingType={StepsE.AIRLINE_PROCESSING_NUMBER} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.HAQIBA_COPY: return <MissedPhoto missingType={StepsE.HAQIBA_COPY} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.PERMISSION_FROM_AIRLINE: return <MissedPhoto missingType={StepsE.PERMISSION_FROM_AIRLINE} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;
            case StepsE.PO_NUMBER: return <MissedPhoto missingType={StepsE.PO_NUMBER} state={state} setValidStep={setValidStep} updateClaim={updateClaim} updateSteps={updateSteps} />;

            case StepsE.DAMAGES: return <PhotosDamage state={state} setValidStep={setValidStep} updateClaim={updateClaim} mobile={mobile} />;
            case StepsE.LUGGAGE: return <PhotosLuggage state={state} setValidStep={setValidStep} updateClaim={updateClaim} mobile={mobile} />;
            case StepsE.CONTACT: return <Contact state={state} setValidStep={setValidStep} updateClaim={updateClaim} mobile={mobile} setCountryName={setCountryName} updateLocation={updateLocation} />;
            case StepsE.SUMMARY: return <Summary state={state} setValidStep={setValidStep} updateState={updateState} setEditSlide={setEditSlide} />;
            case StepsE.MISSING_DOCUMENTS: return <MissingDocuments state={state} setValidStep={setValidStep} countryCode={user.location.countryCode} updateClaim={updateClaim} />;
            case StepsE.MISSING_DOCUMENTS_ORIGINAL: return <DocumentsOriginal state={state} t={t} />;
            case StepsE.FINISH: return <Finish state={state} updateState={updateState} actionFinished={actionFinished} actionNewClaim={actionNewClaim} mobile={mobile} actionHideAvatar={actionHideAvatar} actionNextTags={actionNextTags} />;

            default: return <></>;
        }
    }, [
        actionFinished,
        actionHideAvatar,
        actionNewClaim,
        actionNextTags,
        actionSetEditIndex,
        mobile,
        setCountryName, 
        setEditSlide,
        setStepHidden,
        setValidStep,
        state,
        t,
        updateClaim,
        updateLocation,
        updateState,
        updateSteps,
        user]);

    const actionNoReferenceNumber = () => {
        disableMissingDocuments(true);
        setState({ type: 'GO_NEXT' });
    }

    const confirmReferenceNumber = () => {
        setNumberConfirmIsOpen(false);
        validReferenceNumberAction('invalid');
        disableMissingDocuments();
        // pokaż airline jeśli aktualny krok to reference number
        if (state.step.type === StepsE.REFERENCE_NUMBER) {
            updateSteps([{ type: StepsE.AIRLINE, field: 'optional', value: false }]);
        }
        setState({ type: 'GO_NEXT' });
    }

    const editReferenceNumber = useCallback(() => {
        setNumberConfirmIsOpen(false);
    }, []);

    const actionBack = () => {
        goNextAction(false)
    }

    const actionOpenClaim = (claimId: string) => {
        closeAction();
        navigate(`/${claimId}`);
        // // odczyt ponowy danych o claimach
        // getClaims(false, dispatch, (result) => {
        //     if (result){
        //     }
        // });
    }

    const actionSetTags = (data: StateLocalT['tagNumbersAccepted']) => {
        setState({
            type: 'SET_TAG_NUMBERS_ACCEPTED',
            data
        });
        const foundActualBag = data.find(tag => tag.status === 'processing');
        if (foundActualBag && state.tagNumbers.some(bg => bg.id === foundActualBag.id)) {
            const bag = state.tagNumbers.find(bg => bg.id === foundActualBag.id);
            importPhotoBag(bag);
        }
    }

    const actionGetPossibleAddresses = useCallback(async () => {
        try {
            const result = await getLocationByAddress(addressObject, async (locations) => {
                if (locations) {
                    const addresses: RessultAddressByLocT[] = []
                    const getAddressPromises = locations.map(location => new Promise<void>((resolve, reject) => {
                        getAddressByLoc(location.lat, location.lng, (data) => {
                            data.forEach(address => addresses.push(address));
                            resolve();
                        })
                    }))
                    await Promise.all(getAddressPromises);
                    setPossibleAddresses(addresses);
                }
            });
            return result;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }, [setPossibleAddresses, addressObject]);

    const actionCheckValidByGoogle = useCallback(async () => {
        setState({ type: 'SET_CHECK_CONTACT_ADRESS', data: 'checking' });
        const result = await checkValidByGoogle(addressObject);
        setState({ type: 'SET_CHECK_CONTACT_ADRESS', data: result ? 'resultOk' : 'resultError' });
        actionGetPossibleAddresses()
        return result;
    }, [addressObject, actionGetPossibleAddresses]);

    if (state.steps.length === 0 || !state.step) return <></>;

    return (
        <>
            <div style={{ height, display: 'flex', flexDirection: 'column' }} className={cx(style.form, { [style.mobile]: mobile })} id={'createClaimLayer'}>
                <Header
                    state={state}
                    actionBack={actionBack}
                    closeAction={closeAction}
                    mobile={mobile}
                    t={t}
                />
                <div className={style.bodySlider} id={'bodySlider'}>
                    {chooseContent(state.step)}
                </div>
                <div className={style.buttons} id={'buttonsAddClaim'}>
                    {(state.step.type === 'reference' && !state.editStepIndex) &&
                        <button onClick={actionNoReferenceNumber} disabled={state.loading} className={style.subButton} id={`button_new_claim_no_ref_number`}>
                            {t('createClaim.buttonNoRefNumber')}
                        </button>
                    }
                    {state.lastAddClaimId ?
                        <button
                            disabled={!state.step.valid || state.loading || state.loadingAirline}
                            onClick={() => actionOpenClaim(state.lastAddClaimId)}
                        >
                            {t('createClaim.openClaimButton')}
                        </button> :
                        <Button
                            onClick={() => state.step?.type === 'finish' ? closeAction() : goNextAction()}
                            disabled={!state.step.valid || state.loading || state.loadingAirline || state.checkAddressByGoogle === 'checking'}
                            className={cx({ [style.buttonConfirm]: state.step.type === StepsE.WARNING_ATTACHMENTS })}
                            id={`button_new_claim_step_${state.step.type}`}
                        >
                            {state.editStepIndex !== null ?
                                <>{t('createClaim.buttonBackSummary')}</> :
                                <>
                                    {state.step.type !== StepsE.WARNING_ATTACHMENTS ? state.step?.buttonText : t('reportProblem.infoImagesButton')}
                                </>
                            }
                        </Button>
                    }
                </div>

                <ReferenceNumberConfirmationModal
                    open={numberConfirmIsOpen}
                    close={() => setNumberConfirmIsOpen(false)}
                    confirm={confirmReferenceNumber}
                    edit={editReferenceNumber}
                    referenceNumber={state.claimData.referenceNumber}
                    position={
                        mobile
                            ? ReferenceNumberConfirmationModal.POSITION.BOTTOM
                            : ReferenceNumberConfirmationModal.POSITION.CENTER
                    }
                />
            </div>
            <SaveDataAsDraftModal
                open={state.openDraft}
                onClose={() => setState({ type: 'SET_OPEN_DRAFT', data: false })}
                onConfirm={saveDraft}
                onDeny={discardDraft}
                type={'compact'}
            />
            {!state.airlineSupportedNoRefNum && state.redirectionToAirlineUrl !== '' &&
                <ModalNotSupportedAirlinesAndContacts
                    actionClose={() => setState({ type: 'SET_SHOW_INFO_AIRLINE_SUPPORT_AND_CONTRACT', data: false })}
                    show={!state.showInfoNotAirlineSupportAndContact}
                    url={state.redirectionToAirlineUrl}
                    actionCloseModalMain={() => {
                        setState({ type: 'SET_SHOW_INFO_AIRLINE_SUPPORT_AND_CONTRACT', data: false });
                        setTimeout(() => {
                            onClose();
                        }, 200);
                    }}
                />
            }
            {!state.airlineSupported &&
                <ModalNotAirlineSupported 
                    actionClose={() => setState({ type: 'SET_SHOW_INFO_AIRLINE_SUPPORT', data: false })}
                    airlineCode={state.airlineCode}
                    show={state.showInfoNotAirlineSupport}
                    actionCloseModalMain={() => {
                        setState({ type: 'SET_SHOW_INFO_AIRLINE_SUPPORT', data: false });
                        setTimeout(() => {
                            onClose();
                        }, 200);
                    }}
                />
            }
            {state.airlineSupported && !state.landingStationSupported &&
                <ModalNotLandingStationSupported 
                    actionClose={() => setState({ type: 'SET_SHOW_INFO_LANDING_STATION_SUPPORT', data: false })}
                    landingStationCode={state.landingStationCode}
                    show={state.showInfoNotLandingStationSupport}
                    actionCloseModalMain={() => {
                        setState({ type: 'SET_SHOW_INFO_LANDING_STATION_SUPPORT', data: false });
                        setTimeout(() => {
                            onClose();
                        }, 200);
                    }}
                />
            }
            {state.showModalTagNumbers &&
                <ModalTagNumbers
                    show
                    actionClose={() => {setState({ type: 'CLOSE_MODAL_TAG_NUMBERS' }); setState({ type: 'GO_PREV' })}}
                    tagNumbers={state.tagNumbers}
                    actionSetTags={actionSetTags}
                    t={t}
                />
            }
            <ModalConfirmAddress
                actionClose={() => setState({ type: 'SET_CHECK_CONTACT_ADRESS', data: 'noAction' })}
                actionConfirm={() => setState({ type: 'GO_NEXT' })}
                show={state.checkAddressByGoogle === 'resultError'}
                possibleAddresses={possibleAddresses}
                updateClaim={updateClaim}
                contactData={state.claimData.contact}
            />
            {state.casesInSalesforce.length > 0 &&
                <ModalDuplicatesRN
                    actionClose={() => setState({ type: 'CLEAR_DUPLICATES_RN' })}
                    casesInSalesforce={state.casesInSalesforce}
                    actionCloseModalMain={() => {
                        setState({ type: 'CLEAR_DUPLICATES_RN' });
                        setTimeout(() => {
                            onClose();
                        }, 300);
                    }}
                    referenceNumber={state.claimData.referenceNumber}
                    actionContinue={() => setState({ type: 'GO_NEXT', casesInSalesforce: [] })}
                />
            }
        </>
    );
}
export default React.memo(CreateForm);